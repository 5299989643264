/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateStandardResponseLetterAccessRequest = /* GraphQL */ `
  subscription OnCreateStandardResponseLetterAccessRequest($owner: String) {
    onCreateStandardResponseLetterAccessRequest(owner: $owner) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStandardResponseLetterAccessRequest = /* GraphQL */ `
  subscription OnUpdateStandardResponseLetterAccessRequest($owner: String) {
    onUpdateStandardResponseLetterAccessRequest(owner: $owner) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStandardResponseLetterAccessRequest = /* GraphQL */ `
  subscription OnDeleteStandardResponseLetterAccessRequest($owner: String) {
    onDeleteStandardResponseLetterAccessRequest(owner: $owner) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      email
      npi
      firstName
      lastName
      npiVerified
      classification
      status
      state
      owner
      manualActivationToken
      manualActivationTokenExpiresAt
      manualRecoverToken
      manualRecoverTokenExpiresAt
      registeredAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      email
      npi
      firstName
      lastName
      npiVerified
      classification
      status
      state
      owner
      manualActivationToken
      manualActivationTokenExpiresAt
      manualRecoverToken
      manualRecoverTokenExpiresAt
      registeredAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      email
      npi
      firstName
      lastName
      npiVerified
      classification
      status
      state
      owner
      manualActivationToken
      manualActivationTokenExpiresAt
      manualRecoverToken
      manualRecoverTokenExpiresAt
      registeredAt
      createdAt
      updatedAt
    }
  }
`;
