const state = () => ({
    showSignupModal: false,
    showLoginModal: false,
    showNPIModal: false,
    showPasswordModal: false,
    showProgressModal: false,
    showThankyouModal: false,
    showChangePasswordModal: false,
    showForgotPasswordModal: false,
    showResetPasswordModal: false
})

export default {
  namespaced: true,
  state,
  mutations: {
    showSignupModal(state, show) {
      state.showSignupModal = show;
    },
    showLoginModal(state, show) {
      state.showLoginModal = show;
    },
    showNPIModal(state, show) {
      state.showNPIModal = show;
    },
    showPasswordModal(state, show) {
      state.showPasswordModal = show;
    },
    showChangePasswordModal(state, show) {
      state.showChangePasswordModal = show;
    },
    showProgressModal(state, show) {
      state.showProgressModal = show;
    },
    showThankyouModal(state, show) {
      state.showThankyouModal = show;
    },
    showForgotPasswordModal(state, show){
        state.showForgotPasswordModal = show;
    },
    showResetPasswordModal(state, show){
        state.showResetPasswordModal = show;
    },
    closeAllModals(state) {
        state.showThankyouModal = false;
        state.showProgressModal = false;
        state.showPasswordModal = false;
        state.showNPIModal = false;
        state.showLoginModal = false;
        state.showSignupModal = false;
        state.showForgotPasswordModal = false;
        state.showResetPasswordModal = false;
    }
  },
  actions: {
    setShowSignupModal({ commit }, showSignupModal) {
      commit("showSignupModal", showSignupModal);
    },
    setShowNPIModal({ commit }, showNPIModal) {
      commit("showNPIModal", showNPIModal);
    },
    setShowLoginModal({ commit }, showLoginModal) {
      commit("showLoginModal", showLoginModal);
    },
    setShowPasswordModal({ commit }, showPasswordModal) {
      commit("showPasswordModal", showPasswordModal);
    },
    setShowChangePasswordModal({ commit }, showPasswordModal) {
        commit("showChangePasswordModal", showPasswordModal);
      },
    setShowProgressModal({ commit }, showProgressModal) {
      commit("showProgressModal", showProgressModal);
    },
    setShowThankyouModal({ commit }, showThankyouModal) {
      commit("showThankyouModal", showThankyouModal);
    },
    setShowForgotPasswordModal({ commit }, showForgotPasswordModal) {
        commit("showForgotPasswordModal", showForgotPasswordModal);
    },
    setShowResetPasswordModal({ commit }, showResetPasswordModal) {
        commit("showResetPasswordModal", showResetPasswordModal);
    },
    setCloseAllModals({ commit }){
        commit("closeAllModals");
    }
  }
};
