













import {GRAPHQL_AUTH_MODE} from "@aws-amplify/api-graphql";
import {API} from "aws-amplify";
import {manualUpdateRequest} from "../graphql/mutations";
import Formatter from '../helpers/format'


export default {
  name: 'admin',
  components: {},
  data() {
    return {
      error: null,
      success: null
    }
  },
  mounted: async function () {
    console.log(this.$route.query)

    // check manual approval input
    let {documentNumber, email, token, status} = this.$route.query

    if (!documentNumber || !email || !token || !status) {
      this.error = {
        message: 'Missing parameters'
      }

      return;
    }

    if (status != 'APPROVED' && status != 'DECLINED') {
      this.error = {
        message: 'Invalid status'
      }

      return;
    }
    try {
      this.error = null
      await API.graphql({
        query: manualUpdateRequest,
        variables: {
          input: {
            documentNumber: documentNumber,
            email: email,
            manualActivationToken: token,
            status: status
          }
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });

      // return

      this.success = status=='APPROVED'?'Account approved':'Account rejected'
    } catch (e) {
      let error = Formatter.extractError(e)
      this.error = error
    }
  }
}
