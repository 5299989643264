/**
 * References:
 * - https://dev.to/dabit3/how-to-build-production-ready-vue-authentication-23mk
 * - https://docs.amplify.aws/ui/auth/authenticator/q/framework/vue#recommended-usage
 */
import Vue from "vue";
import VueRouter from "vue-router";

import "@aws-amplify/ui-vue";
import Amplify from "aws-amplify";
import aws_config from "./aws-exports";

import App from "./App.vue";
import HeaderApp from "./HeaderApp.vue";
import HeaderMobileApp from "./HeaderMobileApp.vue";
import FooterApp from "./FooterApp.vue";
import router from "./router";
import store from "./store";
// import './assets/tailwind.css';

import VueCookie from 'vue-cookie';
import GlobalSearch from "@/GlobalSearch.vue";
import PublicationFeedback from "@/PublicationFeedback.vue";
import ManageMyAccount from "@/routes/ManageMyAccount.vue";
import Admin from "@/routes/Admin.vue";
import Recover from "@/routes/Recover.vue";

// import VueGtag from 'vue-gtag';
// import VueAnalytics from 'vue-analytics';


Amplify.configure(aws_config);
Vue.use(VueRouter);
Vue.use(VueCookie);

// Vue.use(VueGtag, {
//     config: { id: "G-QRH2CKZ37K" }
// });

// Vue.use(VueAnalytics, {
//     id: "UA-39141049-8"
// });

Vue.config.productionTip = false;

if (document.getElementById("app")) {
    new Vue({
        render: (h) => h(App),
        router,
        store,
    }).$mount("#app");
}

if (document.getElementById("header")) {
    new Vue({
        render: (h) => h(HeaderApp),
        router,
        store
    }).$mount("#header");
}

if (document.getElementById("header-mobile")) {
    new Vue({
        render: (h) => h(HeaderMobileApp),
        router,
        store
    }).$mount("#header-mobile");
}

if (document.getElementById("footer")) {
    new Vue({
        render: (h) => h(FooterApp),
        router,
        store
    }).$mount("#footer");
}

if (document.getElementById("global-search")) {
    new Vue({
        render: (h) => h(GlobalSearch),
        router,
        store
    }).$mount("#global-search");
}

if (document.getElementById("publication-feedback")) {
    new Vue({
        render: (h) => h(PublicationFeedback),
        router,
        store
    }).$mount("#publication-feedback");
}

if (document.getElementById("manage-my-account")) {
    new Vue({
        render: (h) => h(ManageMyAccount),
        router,
        store
    }).$mount("#manage-my-account");
}

if (document.getElementById("admin-vue")) {
    new Vue({
        render: (h) => h(Admin),
        router,
        store
    }).$mount("#admin-vue");
}
if (document.getElementById("recover-vue")) {
    new Vue({
        render: (h) => h(Recover),
        router,
        store
    }).$mount("#recover-vue");
}
