<template>
  <div>
    <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" aria-label="Close" class="close" @click="closeModal()">
              <span aria-hidden="true">
                <i class="fa-sharp fa-regular fa-xmark"></i>
              </span>
            </button>
            <h2 class="mb-4 text-center" v-if="isSignupFlow">Create an Account</h2>
            <h2 class="mb-4 text-center" v-if="!isSignupFlow">Scientific Response Letter Access Request</h2>

            <div class="mb-3 text-left">
              This content is intended for US Healthcare Professionals. The information you provide us in this form is
              collected in order for us to honor your request for information about our products and investigational
              compounds. We will not use your information for any other purpose. For more information on how to
              opt-out and about our overall privacy practices, please visit our
              <a href="https://www.sagerx.com/privacy-policy">Privacy Policy</a>
              .
              <br/>
              <strong>Already have an account?</strong>
              &nbsp;
              <a href="#" @click="showLogin">Login</a>
            </div>
            <!-- TODO-START: style error container -->

            <div class="alert-content red" v-if="error">
              <div class="alert-description text-center text-sm text-red-600 mt-5">
                {{ error.message }}
              </div>
            </div>
            <!-- TODO-END:-->

            <div class="form-group text-left">
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-4">
                  <label for="password">Create Password*</label>
                  <input type="password"
                         class="form-control"
                         required
                         id="password"
                         placeholder="Create Password..."
                         v-model="form.password"
                  />
                  <div class="text-justify text-xs font-semibold mt-8">
                    Password must include:<br/>
                    <div class="flex items-center ">
                      <i v-if="passwordStrength.case === false " class="fa-sharp fa-regular fa-xmark red"></i>
                      <i v-if="passwordStrength.case === true " class="fa-sharp fa-regular fa-check green"></i>
                      <span>  Uppercase and lowercase letter (A,z)</span><br/>
                    </div>
                    <div class="flex items-center ">
                      <i v-if="passwordStrength.numeric === false " class="fa-sharp fa-regular fa-xmark red"></i>
                      <i v-if="passwordStrength.numeric === true " class="fa-sharp fa-regular fa-check green"></i>
                      <span>  Numeric character (0-9)</span><br/>
                    </div>
                    <div class="flex items-center ">
                      <i v-if="passwordStrength.minlen === false " class="fa-sharp fa-regular fa-xmark red"></i>
                      <i v-if="passwordStrength.minlen === true " class="fa-sharp fa-regular fa-check green"></i>
                      <span>  Minimum of 8 characters</span><br/>
                    </div>

                  </div>

                </div>

                <div class="col-sm-3"></div>
              </div>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-5">
                  <label for="confirm-password">Confirm Password:</label>
                  <input type="password"
                         class="form-control"
                         required
                         id="confirm-password"
                         placeholder="Confirm Password..."
                         v-model="form.confirmPassword"
                  />
                </div>
                <div class="col-sm-3"></div>
              </div>

              <div class="row" v-if="!isSignupFlow">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-5">
                  <input class="form-control" id="checkbox" type="checkbox" v-model="form.sendEmail"/>
                  <label for="checkbox">Send me an email with a link to the Scientific Response
                    Letter</label>
                </div>
                <div class="col-sm-3"></div>
              </div>

              <div class="row align-items-center">
                <div class="col-sm-6 mb-3 text-center text-sm-left">*indicates required field</div>
                <div class="col-sm-6 text-center text-sm-right">
                  <button class="btn btn-primary"
                          type="button"
                          :disabled="progress || formErrors.length>0"
                          @click="next"
                  >
                    <!--                    {{ !progress ? 'NEXT' : '' }}-->Submit
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <div class="modal-backdrop fade show" v-if="open"></div>
  </div>
</template>
<script>
import {Auth, API} from "aws-amplify";
import {completeSearchRequest} from "../../graphql/mutations";
import Validator from '../../helpers/validation'


export default {
  name: "D",
  components: {},
  data() {
    return {
      error: {},
      progress: false,
      form: {}
    }
  },
  computed: {
    isSignupFlow: function () {
      return this.$store.state.workflow.currentStep == 'signup'
    },
    open: function () {
      return this.$store.state.modals.showPasswordModal
    },
    formErrors: function () {
      return Validator.validatePasswordStep(this.form)
    },
    passwordStrength: function () {
      return Validator.validatePasswordStrength(this.form.password)
    }
  },
  methods: {
    showLogin: function () {
      console.log('showLogin')
      this.closeModal()
      this.$store.dispatch('modals/setShowLoginModal', true)
    },
    closeModal: function () {
      this.$store.dispatch('modals/setShowPasswordModal', false)
      this.form = {}
      this.error = {}
      try {
        window.dataLayer.push({
          'event': 'interstitial',
          'click_text': 'CLOSE',
          'interstitial_name': 'Interstitial Sign Up',
          'signup_step': 3

        });
      } catch (e) {
        console.warn('write to DataLayer error')
      }
    },
    next: async function (e) {
      e.preventDefault();

      let errors = Validator.validatePasswordStep(this.form)
      if (errors.length > 0) {
        console.log('ERRORS', errors)
        return;
      }

      // complete search request

      this.progress = true;

      try {
        // signup user
        await Auth.signUp({
          username: this.$store.state.workflow.signupData.email,
          password: this.form.password,
          attributes: {
            email: this.$store.state.workflow.signupData.email,
            "custom:classification": this.$store.state.workflow.npiData.classification,
            "custom:firstName": this.$store.state.workflow.signupData.firstName,
            "custom:lastName": this.$store.state.workflow.signupData.lastName,
            "custom:npi": this.$store.state.workflow.npiData.npi,
            "custom:state": this.$store.state.workflow.signupData.state
          },
        });

        try {
          window.dataLayer.push({
            'event': 'interstitial',
            'click_text': 'SUBMIT',
            'interstitial_name': 'Interstitial Sign Up',
            'signup_step': 3

          });
        } catch (e) {
          console.warn('write to DataLayer error')
        }

        await Auth.signIn(this.$store.state.workflow.signupData.email, this.form.password);

        if (!this.isSignupFlow && this.$store.state.workflow.selectedDocument) {
          await API.graphql({
            query: completeSearchRequest,
            variables: {
              input: {
                documentNumber: this.$store.state.workflow.selectedDocument.document_number__v,
                email: this.$store.state.workflow.signupData.email,
                sendEmail: this.form.sendEmail
              }
            }
          })
        }

        this.form = {};

        this.$store.dispatch('modals/setCloseAllModals')

        if (!this.isSignupFlow) {
          this.$cookie.set('loggedIn', '1', {expires: '7D'});
          let url = `${window.location.origin}/prod/sagerx/document?documentId=${encodeURIComponent(this.$store.state.workflow.selectedDocument.id)}`
          window.open(url, '_blank')
        }
      } catch (e) {
        console.log('error', e)
        this.error = {
          message: e.message ? e.message : 'Unable to complete signup',
          code: 14
        }
      }
      this.progress = false;
    }
  },
  theme: {
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      'not-allowed': 'not-allowed',
      crosshair: 'crosshair',
      'zoom-in': 'zoom-in',
    }
  }
}
</script>
<style scoped>
@media (max-width: 475px) {
  .font-subheading {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
