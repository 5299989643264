const Formatter = {
    extractError: function(responseError){
        let error;

        if(responseError && responseError.errors){
            let firstError = responseError.errors[0]
            if(firstError.errorType=='Lambda:Unhandled'){
                try {
                    let jsonError = JSON.parse(firstError.message)
                    if(jsonError){
                        error = jsonError
                    } else {
                        error = {
                            message: firstError.message,
                            code: 100
                        }
                    }
                } catch(e){
                    error = {
                        message: 'Unable to complete operation',
                        code: 500
                    }
                }

            } else {
                error = {
                    message: firstError.message,
                    code: 100
                }
            }
        }

        return error
    }
}
export default Formatter;
