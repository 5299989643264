/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2rgqev76pfc6zj5xwwlnic5r5m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-woc26vpbf5cojkjetnbbggnie4",
    "aws_cloud_logic_custom": [
        {
            "name": "veevaApi",
            "endpoint": "https://rgexusv6qc.execute-api.us-east-1.amazonaws.com/prodnew",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6vG2h6l3M",
    "aws_user_pools_web_client_id": "44hn6h4ntmid50smgo2k3nokip",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
