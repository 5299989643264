














































































































































































































































































































































































































import API from './api';
import {Auth, Hub} from 'aws-amplify';
import pdfjsLib from 'pdfjs-dist/build/pdf';
// import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import 'pdfjs-dist/web/pdf_viewer.css';
import {PrescribingInformationSection} from '@/@types';

import Collection from './helpers/collection'
import {createVeevaLibraryItem} from "@/helpers/veeva";

// const pdfjsLib = require("pdfjs-dist/build/pdf.min.js");
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js';

export default {
  name: 'Search',
  components: {},
  data: function () {
    return {
      signedIn: false,
      user: null,
      selectedDocument: null,
      progress: false,
      isSearchCompleted: false,
      results: [],
      piSections: [],
      congressPresentations: [],
      journals: [],
      errors: [],
      submittedForm: {},
      searchForm: {
        searchKeywords: '',
        productCode: ''
      },
      srlSelected: true,
      congressSelected: true,
      journalSelected: true,
      srlButtonFilter: false,
      congressButtonFilter: false,
      journalButtonFilter: false,
      showZulressoBanner: false,
      showZuranaloneBanner: false,
      showZurzuvaeBanner: false,
      showSage324Banner: false,
      showSage718Banner: false,
      buttonsResetMode: true,
      showInvestigationalUseBanner: false,
      showApprovedProductBanner: false,
      products: [
        {
          text: 'ZULRESSO® (brexanolone)', value: '00P000000000401'
        },
        {
          text: 'Zurzuvae',
          value: '00P000000000501'
        },
        {
          text: 'Zuranolone',
          value: '00P000000000501'
        },
        {
          text: 'Sage-324', value: 'P000000000A01'
        },
        {
          text: 'Sage-718', value: 'P000000000402'
        }
      ],
      showValidation: false
    };
  },
  beforeCreate: function () {

    console.log('[GlobalSearch] beforeCreate')
    let modal = this.$route.query.modal;

    if (modal) {
      console.log('modal', modal);
      this.$store.dispatch('modals/setShow' + modal, true);
    }

    Hub.listen('auth', (data) => {
      const {payload} = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
      }
      if (payload.event === 'signOut') {
        this.results = [];
        this.searchForm.searchKeywords = '';
        this.piSections = [];
        this.signedIn = false;
      }
    });

    Auth.currentAuthenticatedUser()
        .then((user) => {
          this.user = user;
          this.signedIn = true;
        })
        .catch((e) => {
          console.log('error', e)
          this.user = null;
          this.signedIn = false;
        });
  },
  created: function () {


    // debugger; // eslint-disable-line

    // NOTE: If we don't grab the query params from the route, grab them from the outer window location!
    const urlSearchParams = new URLSearchParams(window.location.search)
    let querySearchTerm = this.$route.query.s ?? urlSearchParams.get('s');
    let product = this.$route.query.productCode ?? urlSearchParams.get('productCode');
    let srl = this.$route.query.srl ?? urlSearchParams.get('srl');
    let congress = this.$route.query.congress ?? urlSearchParams.get('congress');
    let journal = this.$route.query.journal ?? urlSearchParams.get('journal');

    console.log('create', querySearchTerm);

    if (querySearchTerm) {
      this.searchForm.searchKeywords = querySearchTerm;
      this.searchForm.productCode = product;
      this.journalSelected = (journal === '1');
      this.srlSelected = (srl === '1');
      this.congressSelected = (congress === '1');
      this.submitSearch();
    }
  },
  computed: {
    showProductCodeInvalid: function () {
      return this.showValidation && !this.searchForm.productCode
    },
        showSearchKeywordsInvalid: function () {
      return this.showValidation && !this.searchForm.searchKeywords
    },
    isInvalid: function () {
      return this.showValidation && this.isMissingFields
    },
    isMissingFields: function () {
      return !this.searchForm.searchKeywords || !this.searchForm.productCode
    },
    piResults: function (): PrescribingInformationSection[] {
      return this.piSections;
    },
    srlResults: function () {
      console.log("srl results:", this.results);
      return this.results
          .filter((document) => {
            if (document.title.startsWith('VV-MED')) {
              const title = document.title.split('ZULRESSO ')[1];
              document.title = 'ZULRESSO ' + title;
            }
            return document.classification == 'Standard Response';
          });
    },
    congressPresentationResults: function () {
      return this.congressPresentations;
    },
    journalResults: function () {
      return this.journals;
    },
    noResultsFlag: function () {
      return (this.piSections.length == 0 && this.results.length == 0 && this.congressPresentations.length == 0 && this.journals.length == 0)
    },
    totalResults: function () {
      return (this.piSections.length + this.results.length + this.congressPresentations.length + this.journals.length)
    },
    noFilterSelected: function () {
      return !this.srlButtonFilter && !this.congressButtonFilter && !this.journalButtonFilter;
    },
    showFilterButtons: function () {
      let count = 0;
      if (this.srlResults.length > 0) count++;
      if (this.journalResults.length > 0) count++;
      if (this.congressPresentationResults.length > 0) count++;
      return count > 1 && this.isSearchCompleted;
    },
    productLabel: function () {
      let productCode = this.searchForm.productCode;
      console.log('productCode', productCode)
      switch (productCode) {
        case '00P000000000401':
          return 'Approved Products_ZULRESSO® (brexanolone)'
        case '00P000000000501Z':
          return 'Approved Products_ZURZUVAE™ (zuranolone)'
        case '00P000000000501':
          return 'Investigational Compounds_Zuranolone'
        case '00P000000000A01':
          return 'Investigational Compounds_Sage-324'
        case '00P000000000402':
          return 'Investigational Compounds_Sage-718'

        default:
          return ''
      }
    }
  },
  methods: {
    clearSearchQueryString: function () {

      this.searchForm.searchKeywords = '';


      this.$router.replace({
        query: {
          productCode: this.searchForm.productCode,
          s: '',
          srl: this.srlSelected ? '1' : '0',
          journal: this.journalSelected ? '1' : '0',
          congress: this.congressSelected ? '1' : '0'
        }
      });

    },
    trackDocumentAccess: function (name: string, documentNumber?: string) {
      let productCode = this.searchForm.productCode === '00P000000000501Z' ? '00P000000000501' : this.searchForm.productCode;
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'siteSearchResultAccessed',
          productCode: productCode,
          searchTerm: this.searchForm.searchKeywords,
          documentName: name,
          documentNumber
        });
      }
    },
    trackSearch: function (searchResults) {
      const searchKeywords = this.searchForm.searchKeywords;
      let productCode = this.searchForm.productCode === '00P000000000501Z' ? '00P000000000501' : this.searchForm.productCode;
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'siteSearch',
          productCode: productCode,
          searchTerm: searchKeywords,
          searchResultCount: searchResults.length,
          searchResultsArray: searchResults.map((item) => {
            item.name;
          })
        });

        // ## Iterate & track each individual search result as a HIT
        if (searchResults.length) {
          searchResults.forEach((item) => {
            window.dataLayer.push({
              event: 'siteSearchResultDisplayed',
              productCode: productCode,
              searchTerm: searchKeywords,
              documentName: item.name,
              documentNumber: item.documentNumber
            });
          });
        } else {
          // TODO: Track empty results
          window.dataLayer.push({
            event: 'siteSearchNoResults',
            productCode: productCode,
            searchTerm: searchKeywords
          });
        }
      }

      // this.$ga.event('Search', searchKeywords, searchResults)
    },
    submitSrlSearch: async function () {


      const searchKeywordsArray = this.submittedForm.searchKeywords.split(',');
      let productCode = this.searchForm.productCode === '00P000000000501Z' ? '00P000000000501' : this.searchForm.productCode;

      await Promise.all([
//SRL
        API.documentSearch(productCode, searchKeywordsArray.join(' AND '), 'library', 'srl')
            .then((res) => {
              this.results = (res.data?.data?.data ?? []).map(item => createVeevaLibraryItem(item))
              if (this.showZurzuvaeBanner) {
                this.results = this.results.filter(result => !result.isInvestigationalUse);
              }
              this.trackSearch(this.results);
            })
            .catch((e) => {
              // this.noResultsFlag = true;
              console.warn('srl search', e)
              this.results = [];
              this.errors = e;
              this.progress = false;
              this.trackSearch(this.results);
            })
      ]);
    },
    submitCongressSearch: async function () {
      const searchKeywordsArray = this.submittedForm.searchKeywords.split(',');
      let productCode = this.searchForm.productCode === '00P000000000501Z' ? '00P000000000501' : this.searchForm.productCode;

      await Promise.all([
        //Congress Presentations

        API.documentSearch(productCode, searchKeywordsArray.join(' AND '), 'library', 'congress')
            .then((res) => {
              this.congressPresentations = (res.data?.data?.data ?? []).map(item => createVeevaLibraryItem(item))
              if (this.showZurzuvaeBanner) {
                this.congressPresentations = this.congressPresentations.filter(presentation => !presentation.isInvestigationalUse);
              }
              this.trackSearch(this.congressPresentations);
            })
            .catch((e) => {
              console.warn('congress search', e)
              // this.noResultsFlag = true;
              this.congressPresentations = [];
              this.errors = e;
              this.trackSearch(this.congressPresentations);
            }),
      ]);
    },
    submitJournalSearch: async function () {
      const searchKeywordsArray = this.submittedForm.searchKeywords.split(',');
      let productCode = this.searchForm.productCode === '00P000000000501Z' ? '00P000000000501' : this.searchForm.productCode;


      await Promise.all([

        //Journals / Manuscripts
        API.documentSearch(productCode, searchKeywordsArray.join(' AND '), 'library', 'journal')
            .then((res) => {
              this.journals = (res.data?.data?.data ?? []).map(item => createVeevaLibraryItem(item))
              if (this.showZurzuvaeBanner) {
                this.journals = this.journals.filter(journal => !journal.isInvestigationalUse);
              }
              this.trackSearch(this.results);
            })
            .catch((e) => {
              console.warn('journal search', e)
              // this.noResultsFlag = true;
              this.journals = [];
              this.errors = e;
              this.trackSearch(this.results);
            })]);

    },

    clickSubmit: function (e) {
      e.preventDefault();

      if (!this.isMissingFields) {
        this.submitSearch();
      } else {
        this.showValidation = true;
      }
    },
    submitSearch: async function () {
      // e.preventDefault();
      this.showInvestigationalUseBanner = false;
      this.showApprovedProductBanner = false;

      if (this.isMissingFields) {
        this.showValidation = true;
        return;
      }
      this.progress = true;

      this.showZulressoBanner = false;
      this.showZuranaloneBanner = false;
      this.showZurzuvaeBanner = false;
      this.showSage324Banner = false;
      this.showSage718Banner = false;
      this.journalButtonFilter = true;
      this.congressButtonFilter = true;
      this.srlButtonFilter = true;

      let pi_destinations = [];
      this.piSections = []
      this.results = [];
      this.congressPresentations = [];
      this.journals = [];
      this.message = null;
      this.submittedForm = Object.assign({}, this.searchForm);

      const currentQuery = this.$route.query

      console.log('currentQuery', currentQuery);
      const newQuery = {
        productCode: this.submittedForm.productCode,
        s: this.submittedForm.searchKeywords,
        srl: this.srlSelected ? '1' : '0',
        journal: this.journalSelected ? '1' : '0',
        congress: this.congressSelected ? '1' : '0'
      };

      if (!Collection.compareObject(currentQuery, newQuery)) {
        this.$router.replace({query: newQuery});
      }

      let documentTypeArray = [];

      if (this.srlSelected) {
        documentTypeArray.push('Scientific Response Letters')
      }
      if (this.journalSelected) {
        documentTypeArray.push('Journal Publications')
      }
      if (this.congressSelected) {
        documentTypeArray.push('Congress Presentations')
      }
      let dataLayerData = {
        event: 'search',
        searchType: 'Medical Library',
        searchTerm: this.submittedForm.searchKeywords,
        documentType: documentTypeArray,
        product: this.productLabel
      }
      try {
        window.dataLayer.push(dataLayerData);
        // console.log(dataLayerData);
      } catch (e) {
        // empty
      }

      if (this.searchForm.productCode === '00P000000000501') {

        this.showZuranaloneBanner = true;
      }
      if (this.searchForm.productCode === '00P000000000501Z') {

        this.showZurzuvaeBanner = true;
      }
      if (this.searchForm.productCode === '00P000000000A01') {

        this.showSage324Banner = true;
      }
      if (this.searchForm.productCode === '00P000000000402') {

        this.showSage718Banner = true;
      }

      if (this.searchForm.productCode === '00P000000000401') {

        this.showZulressoBanner = true;

      }

      let pdfPath;
      let productName;

      if (this.searchForm.productCode === '00P000000000401') {
        pdfPath = '/content/dam/medical_sagerx_com-v2/documents/prescribing-information-zulresso.pdf';
        productName = 'ZULRESSO';
        this.showZulressoBanner = true;
      } else if (this.searchForm.productCode === '00P000000000501Z') {
        pdfPath = '/content/dam/medical_sagerx_com-v2/documents/zurzuvae-pi.pdf'
        productName = 'ZURZUVAE';
        this.showZurzuvaeBanner = true;
      }


      const loadingTask = pdfjsLib.getDocument(`${window.location.origin}${pdfPath}`);

      console.log('loadingTask', loadingTask);

      if (productName === 'ZULRESSO' || productName === 'ZURZUVAE') {

        console.log('piSections search');

        const pi_searchKeywordsArray = this.submittedForm.searchKeywords.split(',');

        loadingTask.promise
            .then((pdfDocument) => {
              console.log('pdfDocument', pdfDocument);
              pdfDocument.getDestinations().then((dests) => {
                pi_destinations = Object.getOwnPropertyNames(dests);
                let found = false;

                for (const keyword of pi_searchKeywordsArray) {

                  pi_destinations.forEach((pi_destination) => {

                    const pi_destination_keyword = pi_destination.split('--')[0];
                    if (pi_destination_keyword.toLowerCase() === keyword.trimStart().replaceAll(' ', '-').toLowerCase()) {
                      console.log('pi_destination_keyword', pi_destination_keyword, keyword);

                      const piSection = {
                        name: `${productName} Prescribing Information - ` + pi_destination.replaceAll('-', ' ').replaceAll('  ', ' - '),
                        url: `${window.location.origin}/pi-pdf-page?document=${pdfPath}&search=${pi_destination}`
                      };
                      this.piSections.push(piSection);

                      // TODO: Track piSection results to analytics

                      found = true;
                      // this.noResultsFlag = false;
                    }
                  });
                }

                if (found === false) {
                  this.piSections = [];
                }
              });
            })
            .catch((e) => {
              console.log(e);
            });
      }

      if (this.srlSelected) {
        console.log('srlSelected');
        await this.submitSrlSearch();
      }
      if (this.congressSelected) {
        console.log('congressSelected');

        await this.submitCongressSearch();
      }
      if (this.journalSelected) {
        console.log('journalSelected');

        await this.submitJournalSearch();
      }

      await this.containsAtLeastOneInvestigationalUse(this.congressPresentations.concat(this.journalResults).concat(this.srlResults));
      await this.containsAtLeastOneApprovedProduct(this.congressPresentations.concat(this.journalResults).concat(this.srlResults));

      console.log('all searches complete')
      this.isSearchCompleted = true;
      this.progress = false;
    },
    containsAtLeastOneInvestigationalUse: async function (documents) {
      documents.forEach(doc => {
        if (doc.isInvestigationalUse) {
          this.showInvestigationalUseBanner = true;
          return;
        }
      })
      return;
    },
    containsAtLeastOneApprovedProduct: async function (documents) {
      documents.forEach(doc => {
        if (!doc.isInvestigationalUse) {
          this.showApprovedProductBanner = true;
          return;
        }
      })
      return;
    },
    selectPiResult: function (document: PrescribingInformationSection, event) {
      // TODO: Verify we want PiResult tracking as well
      event.preventDefault();
      window.open(document.url, '_blank');

      this.trackDocumentAccess(document.name);
    },
    selectDocument: function (document, event) {
      event.preventDefault();
      if (document.classification == 'Standard Response') {
        this.$store.dispatch('workflow/setSelectedDocument', document);
        this.$store.dispatch('workflow/setSearchData', this.submittedForm);

        // if logged in
        // trigger createsearchrequest (just for history)
        // open document
        // if (this.$store.state.isAuthenticated) {
        if (this.signedIn) {
          let user = this.$store.state.user
          user = this.user;
          console.log('user', user);
          // TODO: How is `workflow/trackSearch` used ? Do we include other tracking stats there?
          this.$store.dispatch('workflow/trackSearch', {
            user: user.attributes
          });

          this.trackDocumentAccess(document.name, document.documentNumber);
          let url = `${window.location.origin}/prod/sagerx/document?documentId=${encodeURIComponent(document.id)}`;
          window.open(url, '_blank');
        } else {
          this.$store.dispatch('workflow/setCurrentStep', 'search');
          this.$store.dispatch('modals/setShowSignupModal', true);

          // TODO: Track inauthenticated users trying to access a document
        }
      } else {
        this.trackDocumentAccess(document.name, document.documentNumber);
        // go to document
        let url = `${window.location.origin}/prod/sagerx/document?documentId=${encodeURIComponent(document.id)}`;
        window.open(url, '_blank');
      }
    },
    resetFilters: function () {
      this.buttonsResetMode = true;
      this.srlButtonFilter = true
      this.congressButtonFilter = true
      this.journalButtonFilter = true
    },
    toggleSrl: function () {
      this.buttonsResetMode = false;
      this.srlButtonFilter = true
      this.congressButtonFilter = false
      this.journalButtonFilter = false
    },
    toggleCongress: function () {
      this.buttonsResetMode = false;
      this.congressButtonFilter = true
      this.srlButtonFilter = false
      this.journalButtonFilter = false
    },
    toggleJournal: function () {
      this.buttonsResetMode = false;
      this.journalButtonFilter = true
      this.congressButtonFilter = false
      this.srlButtonFilter = false
    },
    mounted: function () {
      this.$store.dispatch('modals/setCloseAllModals', true);
    }
  }
};
