import Vue from "vue";
import Vuex from "vuex";
import Modals from "./modules/modals";
import Workflow from "./modules/workflow";
import Profile from "./modules/profile";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    user: {},
  },
  mutations: {
    isAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    setIsAuthenticated({ commit }, isAuthenticated) {
      commit("isAuthenticated", isAuthenticated);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
  },
  modules: {
    modals: Modals,
    workflow: Workflow,
    profile: Profile
  },
});
