






import { Auth, Hub } from "aws-amplify";


export default {
  name: "app",
  components: {

  },
  data() {
    return {
      signedIn: false,
    };
  },
  beforeCreate() {

    console.log('[App] beforeCreate')
    Hub.listen("auth", (data) => {
      console.log("data:", data);
      const { payload } = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
        this.$cookie.set('loggedIn', '1', { expires: '7D'});
        this.$store.dispatch('setIsAuthenticated', true);
        // this.$router.push("/");
      }
      if (payload.event === "signOut") {
        // this.$router.push("/login");
        this.$cookie.delete('loggedIn');

        this.$store.dispatch('setUser', {});
        this.$store.dispatch('setIsAuthenticated', false);
        this.signedIn = false;

        if (this.$route.path === '/manage-my-account') {
          this.$router.push('/')
          // this.$store.dispatch('modals/setShowLoginModal', true)
        }
      }
    });
    Auth.currentAuthenticatedUser()
      .then((user) => {
          this.$cookie.set('loggedIn', '1', { expires: '7D'});
          console.log('current user authenticated', user)
          this.$store.dispatch('setUser', user);
          this.$store.dispatch('setIsAuthenticated', true);
      })
      .catch((e) => {
          this.$cookie.delete('loggedIn');
          console.log('error', e)
          this.$store.dispatch('setUser', {});
          this.$store.dispatch('setIsAuthenticated', false);
      });
  },
};







