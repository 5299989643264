<template>
  <!-- SIGNUP STEP 2 -->
  <div>
    <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" aria-label="Close" class="close" @click="closeModal()">
              <span aria-hidden="true">
                <i class="fa-sharp fa-regular fa-xmark"></i>
              </span>
            </button>
            <h2 class="mb-4 text-center" v-if="isSignupFlow">Create an Account</h2>
            <h2 class="mb-4 text-center" v-if="!isSignupFlow">Scientific Response Letter Access Request</h2>

            <div class="mb-3 text-left">
              This content is intended for US Healthcare Professionals. The information you provide us in this form is
              collected in order for us to honor your request for information about our products and investigational
              compounds. We will not use your information for any other purpose. For more information on how to opt-out
              and about our overall privacy practices, please visit our <a href="https://www.sagerx.com/privacy-policy">Privacy
              Policy</a>.
              &nbsp;<strong>Already have an account?&nbsp;<a href="#" @click="showLogin">Login</a></strong>
            </div>
            <!-- TODO-START: style error container -->

            <div class="alert-content red" v-if="error">
              <div class="alert-description text-center text-sm text-red-600 mt-5">
                {{ error.message }}
                <span v-if="error.code === 105 || error.code === 110" class="underline">
                  <a @click="showForgotPw">Click here</a>
                  .
                </span>
              </div>
            </div>
            <!-- TODO-END:-->

            <div class="form-group text-left">
              <div class="row">
                <div class="col-sm-6 mb-3">
                  <label for="account-fname">First Name*</label>
                  <input type="text"
                         required
                         placeholder="Enter your first name..."
                         class="form-control"
                         v-model="form.firstName"
                  />
                </div>
                <div class="col-sm-6 mb-3">
                  <label for="account-lname">Last Name*</label>
                  <input type="text"
                         required
                         class="form-control"
                         placeholder="Enter your last name..."
                         v-model="form.lastName"
                  />
                </div>
                <div class="col-sm-6 mb-3">
                  <label for="account-email">Email*</label>
                  <input type="email"
                         required

                         class="form-control"
                         placeholder="Enter your email address..."
                         v-model="form.email"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 mb-3 mb-sm-5">
                  <label for="account-npi">Do you have an NPI or state license number?*</label>
                  <div class="d-flex flex-row">
                    <div class="form-check mr-4">
                      <input class="form-check-input"
                             type="radio"
                             required
                             name="account-npi"
                             id="account-npi-yes"
                             value="Yes"
                             v-model="form.npiyn">
                      <label class="form-check-label" for="account-npi-yes">
                        Yes
                      </label>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input"
                             type="radio"
                             required
                             name="account-npi-no" id="account-npi-no"
                             value="No"
                             v-model="form.npiyn">
                      <label class="form-check-label" for="account-npi-no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 mb-5">
                  <label for="account-state-license" v-if="!form.npiyn || form.npiyn === 'Yes'">State of
                    License*</label>
                  <label for="account-state-license" v-if="form.npiyn === 'No'">State of Residence*</label>
                  <select class="form-control"
                          required
                          v-model="form.state">

                    <option value="">State</option>
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AR">AR</option>
                    <option value="AZ">AZ</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DC">DC</option>
                    <option value="DE">DE</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="IA">IA</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="MA">MA</option>
                    <option value="MD">MD</option>
                    <option value="ME">ME</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MO">MO</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="NC">NC</option>
                    <option value="NE">NE</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NV">NV</option>
                    <option value="NY">NY</option>
                    <option value="ND">ND</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="RI">RI</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="WI">WI</option>
                    <option value="WV">WV</option>
                    <option value="WY">WY</option>
                  </select>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-6 mb-3">*indicates required field</div>
                <div class="col-sm-6 text-sm-right">
                  <button class="btn btn-primary" type="button"
                          :disabled="progress || formErrors.length>0" @click="next"
                  >
                    <!--                       {{ !progress ? 'NEXT' : '' }}-->Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="modal-backdrop fade show" v-if="open"></div>
  </div>


</template>
<script>
import Validator from '../../helpers/validation';

export default {
  name: 'A',
  data: function () {
    return {
      progress: false,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        npiyn: '',
        state: ''
      }
    };
  },
  computed: {
    isSignupFlow: function () {
      return this.$store.state.workflow.currentStep == 'signup';
    },
    open: function () {
      return this.$store.state.modals.showSignupModal;
    },
    formErrors: function () {
      return Validator.validateStep1(this.form);
    },
    error: function () {
      return this.$store.state.workflow.error;
    }
  },
  methods: {
    showLogin: function () {
      console.log('showLogin');
      this.closeModal();
      this.$store.dispatch('modals/setShowLoginModal', true);
    },
    showForgotPw: function () {
      console.log('showForgotPw');
      this.closeModal();
      this.$store.dispatch('modals/setShowForgotPasswordModal', true);
    },
    closeModal: function () {
      this.$store.dispatch('modals/setShowSignupModal', false);
      this.$store.dispatch('workflow/resetWorkflowError');
      this.form = {};

      try {
        window.dataLayer.push({
          'event': 'interstitial',
          'click_text': 'CLOSE',
          'interstitial_name': 'Interstitial Sign Up',
          'signup_step': 1

        });
      } catch (e) {
        console.warn('write to DataLayer error')
      }
    },
    next: async function (e) {
      e.preventDefault();

      let errors = Validator.validateStep1(this.form);
      if (errors.length > 0) {
        console.log('ERRORS', errors);
        return;
      }

      try {
        window.dataLayer.push({
          'event': 'interstitial',
          'click_text': 'NEXT',
          'interstitial_name': 'Interstitial Sign Up',
          'signup_step': 1

        });
      } catch (e) {
        console.warn('write to DataLayer error')
      }

      // set form data into workflow store
      this.$store.dispatch('workflow/setSignupData', Object.assign({}, this.form));

      if (this.form.npiyn == 'Yes') {
        this.form = {};

        // close modal
        this.$store.dispatch('modals/setShowSignupModal', false);

        // open srl modal
        this.$store.dispatch('modals/setShowNPIModal', true);
      } else {
        this.form = {};
        // set form data into workflow store
        this.$store.dispatch('workflow/setNPIData', {});

        this.progress = true;
        await this.$store.dispatch('workflow/noNpiWorkflow');
        this.progress = false;
      }

    }
  },
  mounted() {
    // TODO: Remove
//    setTimeout(() => {
//      this.$store.dispatch('modals/setShowSignupModal', true);
//    }, 1000);
  }
};
</script>
<style scoped>
@media (max-width: 475px) {
  .font-subheading {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
