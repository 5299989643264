import { render, staticRenderFns } from "./PasswordModal.vue?vue&type=template&id=20a6637c&scoped=true&"
import script from "./PasswordModal.vue?vue&type=script&lang=js&"
export * from "./PasswordModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a6637c",
  null
  
)

export default component.exports