/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStandardResponseLetterAccessRequest = /* GraphQL */ `
  query GetStandardResponseLetterAccessRequest(
    $email: AWSEmail!
    $documentNumber: String!
  ) {
    getStandardResponseLetterAccessRequest(
      email: $email
      documentNumber: $documentNumber
    ) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listStandardResponseLetterAccessRequests = /* GraphQL */ `
  query ListStandardResponseLetterAccessRequests(
    $email: AWSEmail
    $documentNumber: ModelStringKeyConditionInput
    $filter: ModelStandardResponseLetterAccessRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStandardResponseLetterAccessRequests(
      email: $email
      documentNumber: $documentNumber
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        documentNumber
        email
        status
        searchKeywords
        searchProduct
        documentMetadata
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byEmail = /* GraphQL */ `
  query ByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelStandardResponseLetterAccessRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        documentNumber
        email
        status
        searchKeywords
        searchProduct
        documentMetadata
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($email: AWSEmail!) {
    getUser(email: $email) {
      email
      npi
      firstName
      lastName
      npiVerified
      classification
      status
      state
      owner
      manualActivationToken
      manualActivationTokenExpiresAt
      manualRecoverToken
      manualRecoverTokenExpiresAt
      registeredAt
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: AWSEmail
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        email
        npi
        firstName
        lastName
        npiVerified
        classification
        status
        state
        owner
        manualActivationToken
        manualActivationTokenExpiresAt
        manualRecoverToken
        manualRecoverTokenExpiresAt
        registeredAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
