import axios from 'axios'

export default {
    documentSearch(productCode, keywords, searchType, libraryFilter){
        return axios
        // .get(`${window.location.origin}/veeva`, {
            .get(`https://mshfxbi6xqzzzeeosceeq6ngyq0hjmiw.lambda-url.us-east-1.on.aws/dev/veeva`, {
            params: {
                searchTerm: keywords,
                productCode: productCode,
                searchType: searchType,
                libraryFilter: libraryFilter
            }
        })
    },
    npiValidation(npi, lastName) {
        return axios
        .get(`${window.location.origin}/prod/sagerx/npilookup`, {
            params: {
                npiNumber: npi,
                lastName: lastName
            }
        })
        .then(function(res){
            if(res.data.status=='ok' && res.data.data.totalCount>0){
                return true
            } else {
                return false;
            }
        })
        .catch(function(e){
            console.log('npi error', e)
            return false
        })
    }
}
