









import A from '../components/auth/A.vue'
import B from '../components/auth/B.vue'
import D from '../components/auth/D.vue'

export default {
  name: 'Signup',
  components: {
      A,
      B,
      D
  },
  mounted: function() {
      if(!this.$store.state.isAuthenticated){
        this.$store.dispatch('workflow/setCurrentStep', 'signup')
        this.$store.dispatch('modals/setShowSignupModal', true)
      }
  }
}
