











































import {Auth, Hub} from "aws-amplify";
import LoginModal from "@/components/auth/LoginModal.vue";
import A from "@/components/auth/A.vue";
import B from "@/components/auth/B.vue";
import C from "@/components/auth/C.vue";
import D from "@/components/auth/D.vue";
import E from "@/components/auth/E.vue";
import ResetPasswordModal from "@/components/auth/ResetPasswordModal.vue";


export default {
  name: "HeaderApp",
  components: {
    LoginModal,
    A,
    B,
    C,
    D,
    E,
    ResetPasswordModal
  },
  methods: {
    logout: async function () {
      try {
        await Auth.signOut();
        window.location.assign('/');
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    showLogin: function () {
      console.log('showLogin')
      if (!this.$store.state.isAuthenticated) {
        this.$store.dispatch('modals/setShowLoginModal', true)
      }
    },
    showSignUp: function () {
      console.log('showSignUp')
      if (!this.$store.state.isAuthenticated) {
        this.$store.dispatch('workflow/setCurrentStep', 'signup')
        this.$store.dispatch('modals/setShowSignupModal', true)
      }
    }
  },
  data() {
    return {
      signedIn: false,
      user: null,
      hideRegister: true
    };
  },
  beforeCreate() {
    console.log('[HeaderApp] beforeCreate')
    Hub.listen("auth", (data) => {
      console.log("data:", data);
      const {payload} = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
      }
      if (payload.event === "signOut") {
        this.signedIn = false;
      }
    });
    Auth.currentAuthenticatedUser()
        .then((user) => {
          this.user = user;
          this.signedIn = true;
        })
        .catch((e) => {
          console.log('error', e)
          this.user = null;
          this.signedIn = false;
        });
  },
  mounted() {
    console.log('hash', this.$route.hash)
    let {resetpw, code, username} = this.$route.query
    console.log('username is ', username)
    if (resetpw === 'true' && code && username) {
      this.$store.dispatch('modals/setShowResetPasswordModal', true)
    }
  }
};
