<template>
  <div>

    <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" data-dismiss="modal" aria-label="Close" class="close" @click="closeModal()">
              <span aria-hidden="true">
                <i class="fa-sharp fa-regular fa-xmark"></i>
              </span>
            </button>
            <h2 class="mb-4 text-center">Forgot your password?</h2>

            <p class="">Please enter your email below and we will send you a link to reset your password.</p>

            <!-- TODO-START: style error container -->

            <div class="alert-content red" v-if="error">
              <div class="alert-description text-center text-sm text-red-600 mt-5">
                {{ error.code === 14 ? 'Email Not Found' : error.message }}
              </div>
            </div>
            <!-- TODO-END:-->

            <div class="form-group text-left">
              <label for="login-email">Email*</label>
              <input type="email"
                     required
                     class="form-control mb-3"
                     placeholder="Enter your e-mail..."
                     v-model="form.email"
              />

            </div>

            <div class="text-center">
              <button
                  class="btn btn-primary"
                  type="button"
                  :disabled="progress || formErrors.length>0"
                  @click="next">
                <svg v-if="progress" class="animate-spin h-5 w-5 text-white m-auto" xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <!--                {{ !progress ? 'SUBMIT' : '' }}-->
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-backdrop fade show" v-if="open"></div>

  </div>
</template>
<script>
import Validator from '../../helpers/validation';
import {Auth} from 'aws-amplify';

export default {
  name: 'ForgotPasswordModal',
  data: function () {
    return {
      error: null,
      progress: false,
      form: {
        email: ''
      }
    };
  },
  computed: {
    open: function () {
      return this.$store.state.modals.showForgotPasswordModal;
    },
    formErrors: function () {
      return Validator.validateForgotPassword(this.form);
    }
  },
  methods: {
    closeModal: function () {
      this.$store.dispatch('modals/setShowForgotPasswordModal', false);
      this.form = {};

      try {
        window.dataLayer.push({
          'event': 'interstitial',
          'click_text': 'CLOSE',
          'interstitial_name': 'Interstitial Forgot Password'

        });
      } catch (e) {
        console.warn('write to DataLayer error')
      }

    },
    next: async function (e) {
      e.preventDefault();

      let errors = Validator.validateForgotPassword(this.form);
      if (errors.length > 0) {
        console.log('ERRORS', errors);
        return;
      }

      this.error = null;
      this.progress = true;

      try {
        await Auth.forgotPassword(this.form.email);
        // set form data into workflow store
        this.form = {};

        // close modal
        this.$store.dispatch('modals/setShowForgotPasswordModal', false);
        this.progress = false;


        try {
          window.dataLayer.push({
            'event': 'interstitial',
            'click_text': 'SUBMIT',
            'interstitial_name': 'Interstitial Forgot Password'

          });
        } catch (e) {
          console.warn('write to DataLayer error')
        }

      } catch (e) {
        console.log('error', e);
        this.error = {
          message: e.message ? e.message : 'Unable to activate forgot password procedure',
          code: 14
        };

        this.progress = false;
      }


    }
  }
};
</script>
<style scoped>
@media (max-width: 475px) {
  .font-subheading {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
