/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const startSearchRequest = /* GraphQL */ `
  mutation StartSearchRequest($input: StartSearchRequestInput!) {
    startSearchRequest(input: $input) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const completeSearchRequest = /* GraphQL */ `
  mutation CompleteSearchRequest($input: CompleteSearchRequestInput!) {
    completeSearchRequest(input: $input) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const recoverRequest = /* GraphQL */ `
  mutation RecoverRequest($input: RecoverRequestInput!) {
    recoverRequest(input: $input) {
      documentNumber
      documentMetadata
      email
      npi
      state
      firstName
      lastName
      classification
    }
  }
`;
export const manualUpdateRequest = /* GraphQL */ `
  mutation ManualUpdateRequest($input: ManualUpdateInput!) {
    manualUpdateRequest(input: $input)
  }
`;
export const createStandardResponseLetterAccessRequest = /* GraphQL */ `
  mutation CreateStandardResponseLetterAccessRequest(
    $input: CreateStandardResponseLetterAccessRequestInput!
    $condition: ModelStandardResponseLetterAccessRequestConditionInput
  ) {
    createStandardResponseLetterAccessRequest(
      input: $input
      condition: $condition
    ) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateStandardResponseLetterAccessRequest = /* GraphQL */ `
  mutation UpdateStandardResponseLetterAccessRequest(
    $input: UpdateStandardResponseLetterAccessRequestInput!
    $condition: ModelStandardResponseLetterAccessRequestConditionInput
  ) {
    updateStandardResponseLetterAccessRequest(
      input: $input
      condition: $condition
    ) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteStandardResponseLetterAccessRequest = /* GraphQL */ `
  mutation DeleteStandardResponseLetterAccessRequest(
    $input: DeleteStandardResponseLetterAccessRequestInput!
    $condition: ModelStandardResponseLetterAccessRequestConditionInput
  ) {
    deleteStandardResponseLetterAccessRequest(
      input: $input
      condition: $condition
    ) {
      documentNumber
      email
      status
      searchKeywords
      searchProduct
      documentMetadata
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      email
      npi
      firstName
      lastName
      npiVerified
      classification
      status
      state
      owner
      manualActivationToken
      manualActivationTokenExpiresAt
      manualRecoverToken
      manualRecoverTokenExpiresAt
      registeredAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      email
      npi
      firstName
      lastName
      npiVerified
      classification
      status
      state
      owner
      manualActivationToken
      manualActivationTokenExpiresAt
      manualRecoverToken
      manualRecoverTokenExpiresAt
      registeredAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      email
      npi
      firstName
      lastName
      npiVerified
      classification
      status
      state
      owner
      manualActivationToken
      manualActivationTokenExpiresAt
      manualRecoverToken
      manualRecoverTokenExpiresAt
      registeredAt
      createdAt
      updatedAt
    }
  }
`;
