<template>


     <div>
       <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
         <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
           <div class="modal-content">
             <div class="modal-body">
               <button type="button" data-dismiss="modal" aria-label="Close" class="close" @click="closeModal()">
              <span aria-hidden="true">
                <i class="fa-sharp fa-regular fa-xmark"></i>
              </span>
               </button>
               <h2 class="mb-4 text-center">
                 Thank you for visiting the Sage Therapeutics Medical Hub
               </h2>
               <p class="text-center">We were unable to verify your licensing credentials and will contact you via email<br> within 1 business day to confirm your request.</p>
               <br/>
             </div>
           </div>
         </div>
       </div>


       <div class="modal-backdrop fade show" v-if="open"></div>
    </div>

</template>


<script>

const colors = require('tailwindcss/colors')

module.exports = {
    name: "E",
    data(){
        return{
        }
    },
    computed: {
        open: function() {
            return this.$store.state.modals.showThankyouModal
        }
    },
    methods:{
        closeModal: function(){
            this.$store.dispatch('modals/setShowThankyouModal', false)
            this.$store.dispatch('workflow/resetWorkflow')
        }
    },
    theme: {
        extend: {
        colors: {
            'light-blue': colors.lightBlue,
            cyan: colors.cyan,
        },
        animation: {
        },
        keyframes: {
        },
        },
    },
    variants: {
        extend: {},
    },
    plugins: [],
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>










