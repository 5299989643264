import {API} from "aws-amplify";
//import { updateUser } from "../../graphql/mutations";
import {getUser, listStandardResponseLetterAccessRequests} from "../../graphql/queries";
//import Formatter from '../../helpers/format'
import moment from 'moment';

const state = () => ({
    requests: [],
    profile: {},
    error: null
})

const allowedClassifications = [
    'md', 'do', 'pharmd', 'rph', 'rn', 'np', 'payer'
]

export default {
    namespaced: true,
    state,
    mutations: {
        setProfileData(state, data) {
            state.profile = {...state.profile, ...data};
        },
        setRequests(state, requests) {
            state.requests = requests
        },
        setError(state, error) {
            state.error = error
        }
    },
    actions: {
        setRequests({commit}, requests) {
            commit("setRequests", requests);
        },
        setProfileData({commit}, profileData) {
            commit("setProfileData", profileData);
        },
        async loadProfile({commit}, email) {
            let userResponse = await API.graphql({
                query: getUser,
                //@ts-ignore
                authMode: 'AMAZON_COGNITO_USER_POOLS',
                variables: {
                    //@ts-ignore
                    email: email
                }
            })

            console.log('user response', userResponse)

            let response = await API.graphql({
                query: listStandardResponseLetterAccessRequests,
                variables: {
                    filter: {
                        email: {eq: email}
                    }
                }
            })

            console.log('response', response)

            //@ts-ignore
            let profileData = userResponse.data.getUser
            if (!allowedClassifications.includes(profileData.classification)) {
                profileData.otherClassification = profileData.classification
                profileData.classification = 'other'
            }

            commit("setProfileData", profileData)

            //@ts-ignore
            let requestItems = response.data.listStandardResponseLetterAccessRequests.items.filter((i) => {
                return i.documentNumber != 'NA'
            }).map((i) => {
                i.documentMetadata = JSON.parse(i.documentMetadata)
                i.date = moment(i.createdAt).format('MMMM DD, YYYY')
                return i;
            })
            
            commit("setRequests", requestItems)
        }
    }
};
