<template>

  <div>

    <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h2 class="mb-4 text-center">
              Please wait while we process your request
            </h2>
            <br/>


          <div class="text-center">
            <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px"
                 viewBox="0 0 128 128" xml:space="preserve"><g><circle cx="16" cy="64" r="16" fill="#000000"/><circle
                cx="16" cy="64" r="14.344" fill="#000000" transform="rotate(45 64 64)"/><circle cx="16" cy="64"
                                                                                                r="12.531"
                                                                                                fill="#000000"
                                                                                                transform="rotate(90 64 64)"/><circle
                cx="16" cy="64" r="10.75" fill="#000000" transform="rotate(135 64 64)"/><circle cx="16" cy="64"
                                                                                                r="10.063"
                                                                                                fill="#000000"
                                                                                                transform="rotate(180 64 64)"/><circle
                cx="16" cy="64" r="8.063" fill="#000000" transform="rotate(225 64 64)"/><circle cx="16" cy="64"
                                                                                                r="6.438" fill="#000000"
                                                                                                transform="rotate(270 64 64)"/><circle
                cx="16"
                cy="64"
                r="5.375"
                fill="#000000"
                transform="rotate(315 64 64)"/><animateTransform
                attributeName="transform"
                type="rotate"
                values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
                calcMode="discrete"
                dur="720ms"
                repeatCount="indefinite"></animateTransform></g></svg>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal-backdrop fade show" v-if="open"></div>
  </div>
</template>


<script>
module.exports = {
  name: 'C',
  data() {
    return {};
  },
  computed: {
    open: function () {
      return this.$store.state.modals.showProgressModal;
    }
  },
  theme: {
    extend: {
      animation: {},
      keyframes: {}
    }
  },
  variants: {
    extend: {}
  },
  plugins: []
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>










