<template>
  <!-- SIGNUP STEP 2  (Check NPI) -->
  <div>
    <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" aria-label="Close" class="close" @click="closeModal()">
              <span aria-hidden="true">
                <i class="fa-sharp fa-regular fa-xmark"></i>
              </span>
            </button>
            <h2 class="mb-4 text-center" v-if="isSignupFlow">Create an Account</h2>
            <h2 class="mb-4 text-center" v-if="!isSignupFlow">Scientific Response Letter Access Request</h2>

            <div class="mb-3 text-left">
              This content is intended for US Healthcare Professionals. The information you provide us in this form is
              collected in order for us to honor your request for information about our products and investigational
              compounds. We will not use your information for any other purpose. For more information on how to opt-out
              and about our overall privacy practices, please visit our
              <a href="https://www.sagerx.com/privacy-policy">Privacy Policy</a>
              .
              <br/>
              <strong>Already have an account?</strong>
              &nbsp;
              <a href="#" @click="showLogin">Login</a>
            </div>
            <!-- TODO-START: style error container -->

            <div class="alert-content red" v-if="error">
              <div class="alert-description text-center text-sm text-red-600 mt-5">
                {{ error.message }}
                <span v-if="error.code === 105 || error.code === 110" class="underline">
                  <a @click="showForgotPw">Click here</a>
                  .
                </span>
              </div>
            </div>
            <!-- TODO-END:-->

            <div class="form-group text-left">
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-4">
                  <label for="account-state-license">Professional Designation*</label>
                  <select class="form-control"
                          required
                          id="account-state-license"
                          v-model="form.classification">
                    <option value="">Select</option>
                    <option value="md">MD</option>
                    <option value="do">DO</option>
                    <option value="pharmd">PharmD</option>
                    <option value="rph">RPh</option>
                    <option value="rn">RN</option>
                    <option value="np">NP</option>
                    <option value="payer">Payer</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div class="col-sm-3"></div>
              </div>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-5" v-if="form.classification === 'other'">
                  <label for="classification-other">Other (Please specify)*</label>
                  <input type="text"
                         id="classification-other"
                         class="form-control"
                         placeholder="Other Professional Designation..."
                         v-model="form.otherClassification"
                  />
                </div>

                <div class="col-sm-3"></div>
              </div>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-5">
                  <label for="account-state-license">NPI or state license number*</label>
                  <input type="text"
                         class="form-control"
                         required
                         id="account-state-license"
                         placeholder="Enter your NPI or license number..."
                         v-model="form.npi"
                  />
                </div>
                <div class="col-sm-3"></div>
              </div>

              <div class="row align-items-center">
                <div class="col-sm-6 mb-3 text-center text-sm-left">*indicates required field</div>
                <div class="col-sm-6 text-center text-sm-right">
                  <span class="step-back mr-5">
                    <a href="#"
                       class="link-black"
                       @click="showSignup">
                      BACK
                    </a>
                  </span>
                  <button class="btn btn-primary"
                          type="button"
                          :disabled="progress || formErrors.length>0"
                          @click="next"
                  >
                    <!--                    {{ !progress ? 'NEXT' : '' }}-->Next
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <div class="modal-backdrop fade show" v-if="open"></div>


  </div>
</template>
<script>
import Validator from '../../helpers/validation';

export default {
  name: 'B',
  data: function () {
    return {
      progress: false,
      form: {}
    };
  },
  computed: {
    isSignupFlow: function () {
      return this.$store.state.workflow.currentStep == 'signup';
    },
    open: function () {
      return this.$store.state.modals.showNPIModal;
    },
    formErrors: function () {
      return Validator.validateStep2(this.form);
    },
    error: function () {
      return this.$store.state.workflow.error;
    }
  },
  methods: {
    showLogin: function () {
      console.log('showLogin');
      this.closeModal();
      this.$store.dispatch('modals/setShowLoginModal', true);
    },
    showForgotPw: function () {
      console.log('showForgotPw');
      this.closeModal();
      this.$store.dispatch('modals/setShowForgotPasswordModal', true);
    },
    closeModal: function () {
      this.$store.dispatch('modals/setShowNPIModal', false);
      this.$store.dispatch('workflow/resetWorkflowError');
      this.form = {};
      try {
        window.dataLayer.push({
          'event': 'interstitial',
          'click_text': 'CLOSE',
          'interstitial_name': 'Interstitial Sign Up',
          'signup_step': 2

        });
      } catch (e) {
        console.warn('write to DataLayer error')
      }
    },
    showSignup: function () {
      // BACK
      console.log('showSignup');
      this.$store.dispatch('modals/setShowSignupModal', true);
      this.closeModal();
    },
    next: async function (e) {
      e.preventDefault();

      let errors = Validator.validateStep2(this.form);
      if (errors.length > 0) {
        console.log('ERRORS', errors);
        return;
      }

      try {
        window.dataLayer.push({
          'event': 'interstitial',
          'click_text': 'NEXT',
          'interstitial_name': 'Interstitial Sign Up',
          'signup_step': 2

        });
      } catch (e) {
        console.warn('write to DataLayer error')
      }

      // set form data into workflow store
      this.$store.dispatch('workflow/setNPIData', Object.assign({}, this.form));

      this.form = {};

      this.progress = true;
      await this.$store.dispatch('workflow/startWorkflow');
      this.progress = false;
    }
  },
  mounted() {
    // TODO: Remove
//    setTimeout(() => {
//      this.$store.dispatch('modals/setShowNPIModal', true);
//    }, 1000);
  }
};
</script>
<style scoped>
@media (max-width: 475px) {
  .font-subheading {
    font-size: 14px;
    line-height: 16px;
  }
}
</style>




