<template>
  <div>
    <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" aria-label="Close" class="close" @click="closeModal()">
              <span aria-hidden="true">
                <i class="fa-sharp fa-regular fa-xmark"></i>
              </span>
            </button>
            <h2 class="mb-4 text-center">Update Password</h2>

            <div class="alert-content red" v-if="error">
              <div class="alert-description text-center text-sm text-red-600 mt-5">
                {{ error.message }}
              </div>
            </div>
            <!-- TODO-END:-->

            <div class="form-group text-left">
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-4">
                  <label for="confirmPassword">Old Password*</label>
                  <input type="password"
                         class="form-control"
                         required
                         id="confirmPassword"
                         placeholder="Old Password..."
                         v-model="form.oldPassword"
                  />


                </div>

                <div class="col-sm-3"></div>
              </div>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 mb-5">
                  <label for="account-state-license">Change Password:</label>
                  <input type="password"
                         class="form-control"
                         required
                         id="account-state-license"
                         placeholder="Change Password..."
                         v-model="form.password"
                  />
                  <div>
                    Password must include:<br/>
                    <div class="flex items-center ">
                      <i v-if="passwordStrength.case === false " class="fa-sharp fa-regular fa-xmark red"></i>
                      <i v-if="passwordStrength.case === true " class="fa-sharp fa-regular fa-check green"></i>
                      <span>  Uppercase and lowercase letter (A,z)</span><br/>
                    </div>
                    <div class="flex items-center ">
                      <i v-if="passwordStrength.numeric === false " class="fa-sharp fa-regular fa-xmark red"></i>
                      <i v-if="passwordStrength.numeric === true " class="fa-sharp fa-regular fa-check green"></i>
                      <span>  Numeric character (0-9)</span><br/>
                    </div>
                    <div class="flex items-center ">
                      <i v-if="passwordStrength.minlen === false " class="fa-sharp fa-regular fa-xmark red"></i>
                      <i v-if="passwordStrength.minlen === true " class="fa-sharp fa-regular fa-check green"></i>
                      <span>  Minimum of 8 characters</span><br/>
                    </div>
                  </div>

                </div>
                <div class="col-sm-3"></div>
              </div>

              <div class="row align-items-center">
                <div class="col-sm-6 mb-3 text-center text-sm-left">*indicates required field</div>
                <div class="col-sm-6 text-center text-sm-right">
                  <button class="btn btn-primary"
                          type="button"
                          :disabled="progress || formErrors.length>0"
                          @click="next"
                  >
                    <!--                    {{ !progress ? 'NEXT' : '' }}-->Submit
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <div class="modal-backdrop fade show" v-if="open"></div>
  </div>
</template>
<script>
import {Auth} from "aws-amplify";
import Validator from '../../helpers/validation'

export default {
  name: "PasswordModal",
  data() {
    return {
      error: {},
      progress: false,
      form: {}
    }
  },
  computed: {
    open: function () {
      return this.$store.state.modals.showChangePasswordModal
    },
    formErrors: function () {
      return Validator.validateChangePassword(this.form)
    },
    passwordStrength: function () {
      return Validator.validatePasswordStrength(this.form.password)
    }
  },
  methods: {
    closeModal: function () {
      this.$store.dispatch('modals/setShowChangePasswordModal', false)
      this.form = {}
      this.error = {}
    },
    next: async function (e) {
      e.preventDefault();

      let errors = Validator.validateChangePassword(this.form)
      if (errors.length > 0) {
        console.log('ERRORS', errors)
        return;
      }

      // complete search request
      this.error = null
      this.progress = true;

      try {
        Auth.currentAuthenticatedUser()
            .then(user => {
              return Auth.changePassword(user, this.form.oldPassword, this.form.password).then(status => {
                console.log('status', status)
                this.progress = false;
                this.$store.dispatch('modals/setShowChangePasswordModal', false)
              });
            })
            .catch(e => {
              console.log('error', e)
              this.error = {
                message: e.message ? e.message : 'Unable to complete change password procedures',
                code: 14
              }
            })
      } catch (e) {
        console.log('error', e)
        this.error = {
          message: e.message ? e.message : 'Unable to complete change password procedures',
          code: 14
        }
      }
      this.progress = false;
    }
  },
  theme: {
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      'not-allowed': 'not-allowed',
      crosshair: 'crosshair',
      'zoom-in': 'zoom-in',
    }
  }
}
</script>
<style scoped>

</style>
