<template>
<div>
  <div class="modal fade show" id="login-medhub" role="dialog" style="display:block;" v-if="open">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" aria-label="Close" class="close" @click="closeModal()">
              <span aria-hidden="true">
                <i class="fa-sharp fa-regular fa-xmark"></i>
              </span>
          </button>
          <h2 class="mb-4 text-center">Reset your Password</h2>
          <!-- TODO-START: style error container -->

          <div class="alert-content red" v-if="error">
            <div class="alert-description text-center text-sm text-red-600 mt-5">
              {{ error.message }}
            </div>
          </div>
          <!-- TODO-END:-->

          <div class="form-group text-left">
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6 mb-4">
                <label for="account-state-license">Create Password*</label>
                <input type="password"
                       class="form-control"
                       required
                       id="password"
                       placeholder="Create Password..."
                       v-model="form.password"
                />
                <div class="text-justify text-xs font-semibold mt-8">
                  <strong>Password must include:</strong><br/>
                  <div class="flex items-center ">
                    <svg v-if="passwordStrength.case"
                         :class="{'text-red-500': passwordStrength.case===false, 'text-green-400': passwordStrength.case===true}"
                         class=" text-center fill-current mr-0.5 " xmlns="http://www.w3.org/2000/svg" width="10"
                         height="10" viewBox="0 0 24 24">
                      <path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/>
                    </svg>
                    <svg v-if="!passwordStrength.case"
                         :class="{'text-red-500': passwordStrength.case===false, 'text-green-400': passwordStrength.case===true}"
                         class="text-center fill-current mr-0.5" xmlns="http://www.w3.org/2000/svg" width="10"
                         height="10" viewBox="0 0 24 24">
                      <path
                          d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
                    </svg>
                    <span :class="{red: passwordStrength.case==false, green: passwordStrength.case==true}">Uppercase and lowercase letter (A,z)</span><br/>
                  </div>
                  <div class="flex items-center">
                    <svg v-if="passwordStrength.numeric"
                         :class="{'text-red-500': passwordStrength.numeric===false, 'text-green-400': passwordStrength.numeric===true}"
                         class=" text-center fill-current mr-0.5 " xmlns="http://www.w3.org/2000/svg" width="10"
                         height="10" viewBox="0 0 24 24">
                      <path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/>
                    </svg>
                    <svg v-if="!passwordStrength.numeric"
                         :class="{'text-red-500': passwordStrength.numeric===false, 'text-green-400': passwordStrength.numeric===true}"
                         class="text-center fill-current mr-0.5" xmlns="http://www.w3.org/2000/svg" width="10"
                         height="10" viewBox="0 0 24 24">
                      <path
                          d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
                    </svg>
                    <span :class="{red: passwordStrength.numeric===false, green: passwordStrength.numeric===true}"> Numeric character (0-9)</span><br/>
                  </div>
                  <div class="flex items-center ">
                    <svg v-if="passwordStrength.minlen"
                         :class="{'text-red-500': passwordStrength.minlen===false, 'text-green-400': passwordStrength.minlen===true}"
                         class=" text-center fill-current mr-0.5 " xmlns="http://www.w3.org/2000/svg" width="10"
                         height="10" viewBox="0 0 24 24">
                      <path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/>
                    </svg>
                    <svg v-if="!passwordStrength.minlen"
                         :class="{'text-red-500': passwordStrength.minlen===false, 'text-green-400': passwordStrength.minlen===true}"
                         class="text-center fill-current mr-0.5" xmlns="http://www.w3.org/2000/svg" width="10"
                         height="10" viewBox="0 0 24 24">
                      <path
                          d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
                    </svg>
                    <span :class="{red: passwordStrength.minlen===false, green: passwordStrength.minlen===true}">Minimum of 8 characters</span>
                  </div>
                </div>

              </div>

              <div class="col-sm-3"></div>
            </div>
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-6 mb-5">
                <label for="account-state-license">Confirm Password:</label>
                <input type="password"
                       class="form-control"
                       required
                       id="account-state-license"
                       placeholder="Confirm Password..."
                       v-model="form.confirmPassword"
                />
              </div>
              <div class="col-sm-3"></div>
            </div>

            <div class="row align-items-center">
              <div class="col-sm-6 mb-3 text-center text-sm-left">*indicates required field</div>
              <div class="col-sm-6 text-center text-sm-right">
                <button class="btn btn-primary"
                        type="button"
                        :disabled="progress || formErrors.length>0"
                        @click="next"
                >
                  <!--                    {{ !progress ? 'NEXT' : '' }}-->Submit
                </button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="modal-backdrop fade show" v-if="open"></div>

     </div>
</template>
<script>
import { Auth } from "aws-amplify";
import Validator from '../../helpers/validation'

export default {
    name:"ResetPasswordModal",
    data(){
        return{
            error: {},
            progress: false,
            form: {}
        }
    },
    computed: {
        open: function() {
            return this.$store.state.modals.showResetPasswordModal
        },
        formErrors: function(){
            return Validator.validateResetPassword(this.form)
        },
        passwordStrength: function(){
            return Validator.validatePasswordStrength(this.form.password)
        }
    },
    methods:{
        closeModal: function(){
            this.$store.dispatch('modals/setShowResetPasswordModal', false)
            this.form = {}
            this.error = {}
            this.$router.push('/')
        },
        next: async function(e){
            e.preventDefault();

            let errors = Validator.validateResetPassword(this.form)
            if(errors.length>0){
                console.log('ERRORS', errors)
                return;
            }

            // complete search request
            this.error = null
            this.progress = true;

            try {
                let { code, username } = this.$route.query

                await Auth.forgotPasswordSubmit(username, code, this.form.password);
                this.form = {}
                this.progress = false;
                this.$store.dispatch('modals/setShowResetPasswordModal', false)
                this.$router.push('/')
            } catch(e){
                console.log('error', e)
                this.error = {
                    message: e.message?e.message:'Unable to complete reset password procedure',
                    code: 14
                }
            }
            this.progress = false;
        }
    },
    theme: {
        cursor: {
            auto: 'auto',
            default: 'default',
            pointer: 'pointer',
        wait: 'wait',
            text: 'text',
        move: 'move',
            'not-allowed': 'not-allowed',
        crosshair: 'crosshair',
        'zoom-in': 'zoom-in',
        }
    }
}
</script>
<style scoped>

</style>
