
















import { Auth } from "aws-amplify";
export default {
  name: "home",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async signIn() {
      const { username, password } = this.form;
      await Auth.signIn(username, password);
    },
  },
};
