var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
};

var sortBy = function(xs, key) {
    function compare(a, b) {
        if (a[key] < b[key])
            return -1;
        if (a[key] > b[key])
            return 1;
        return 0;
    }

    return xs.sort(compare);
}


const isObject = (obj) => {
    return (Object.prototype.toString.call(obj) === '[object Object]');
}

function compareObject(obj1, obj2){
    if(!isObject(obj1) || !isObject(obj2)){
        return false;
    }

    let len = null;
    //check if they're of thesame length
    if(Object.keys(obj1).length != Object.keys(obj2).length){
        return false;
    }else{
        len = Object.keys(obj1).length; //use any length
    }

    let match = 0; //store number of matched properties
    Object.keys(obj1).forEach(i => {
        //check if values with the same keys are equal
        if(obj1[i] === obj2[i]){
            match++; //increment the variable
        }
    })
    //check if object length equals the number of matched properties
    if(match === len){
        return true;
    }

    return false;
}
const Collection = {
    groupBy,
    sortBy,
    compareObject,
    isObject
}
export default Collection;
