







import { Auth } from 'aws-amplify'

export default {
  name: 'Profile',
  data() {
    return {
      user: {}
    }
  },
  methods: {
      logout: async function(){
            try {
                await Auth.signOut();
                window.location.assign('/');
            } catch (error) {
                console.log('error signing out: ', error);
            }
      }
  },
  beforeCreate() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.user = user
      })
      .catch(() => console.log('not signed in...'))
  }
}
