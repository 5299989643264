<template>
  <div id="publication-feedback">
    <div class="row" v-if="!isYesClicked">
      <div class="col-12">
        <hr class="mb-5"/>
      </div>
    </div>
    <div class="container mb-4" v-if="!isYesClicked && !isNoClicked">
      <div class="row">
        <div class="col-12 col-lg-8">
          <h2>Did you find what you were looking for?</h2>
        </div>
        <div class="col-12 col-lg-4 text-right">
          <button class="btn btn-lg btn-wire btn-blue-outline mx-2" @click="clickYes()">Yes
            <i
                class="fa-regular fa-thumbs-up ml-3"></i>
          </button>
          <button class="btn btn-lg btn-wire btn-blue-outline mx-2" @click="clickNo()">No
            <i
                class="fa-regular fa-thumbs-down ml-3"></i>
          </button>

        </div>
      </div>
    </div>
    <div class="container mb-4" v-if="isNoClicked || isYesClicked">
      <div class="row">
        <div class="col-12">
          <p class="text-center">Thank you! If you would like to provide more input, please consider
            <a
                href="/connect-with-us/share-feedback-form">submitting a feedback form
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicationFeedback',
  data: function () {
    return {
      isYesClicked: false,
      isNoClicked: false
    };
  },
  computed: {},
  methods: {
    clickYes: function () {
      this.isYesClicked = true;
      try {
        window.dataLayer.push({
          event: 'publicationFeedback',
          rating: 'thumbs up'
        });
      } catch (e) {
        // ignore
      }
    },
    clickNo: function () {
      this.isNoClicked = true;
      try {
        window.dataLayer.push({
          event: 'publicationFeedback',
          rating: 'thumbs down'
        });
      } catch (e) {
        // ignore
      }
    }
  }
};
</script>
