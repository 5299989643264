













import D from '../components/auth/D.vue'
import {GRAPHQL_AUTH_MODE} from "@aws-amplify/api-graphql";
import {API} from "aws-amplify";
import {recoverRequest} from "../graphql/mutations";
import Formatter from '../helpers/format'


export default {
  name: 'recover',
  components: {
    D
  },
  data() {
    return {
      error: null
    }
  },
  mounted: async function () {
    console.log(this.$route.query)

    // check manual approval input
    let {documentNumber, email, token} = this.$route.query

    if (!documentNumber || !email || !token) {
      this.error = {
        message: 'Missing parameters'
      }

      return;
    }

    try {
      const response = await API.graphql({
        query: recoverRequest,
        variables: {
          input: {
            documentNumber: documentNumber,
            email: email,
            manualRecoverToken: token
          }
        },
        authMode: GRAPHQL_AUTH_MODE.API_KEY
      });

      console.log('response', response)
      // init workflow

      // @ts-ignore
      let document = response.data.recoverRequest

      let metadata = {}
      if(document.documentMetadata){
        metadata = JSON.parse(document.documentMetadata)
      }
      let signupData = {}
      signupData['email'] = document.email
      signupData['firstName'] = document.firstName
      signupData['lastName'] = document.lastName
      signupData['state'] = document.state

      let npiData = {}
      npiData['classification'] = document.classification
      npiData['npi'] = document.npi

      this.$store.dispatch('workflow/setSelectedDocument', metadata)
      this.$store.dispatch('workflow/setSignupData', signupData)
      this.$store.dispatch('workflow/setNPIData', npiData)

      if(document.documentNumber=='NA'){
          this.$store.dispatch('workflow/setCurrentStep', 'signup')
      } else {
          this.$store.dispatch('workflow/setCurrentStep', 'search')
      }

      this.$store.dispatch('modals/setShowPasswordModal', true)

      // open signup flow

      this.$router.replace('/');
    } catch (e) {
      let error = Formatter.extractError(e)
      this.error = error
    }
  }
}
