













import {Auth, Hub} from "aws-amplify";


export default {
  name: "FooterApp",
  components: {},
  methods: {
    logout: async function () {
      try {
        await Auth.signOut();
        window.location.assign('/');
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    showLogin: function () {
      console.log('showLogin')
      if (!this.$store.state.isAuthenticated) {
        this.$store.dispatch('modals/setShowLoginModal', true)
      }
    },
    showSignUp: function () {
      console.log('showSignUp')
      if (!this.$store.state.isAuthenticated) {
        this.$store.dispatch('workflow/setCurrentStep', 'signup')
        this.$store.dispatch('modals/setShowSignupModal', true)
      }
    }
  },
  data() {
    return {
      signedIn: false,
      user: null,
      hideRegister: true
    };
  },
  beforeCreate() {

    console.log('[FooterApp] beforeCreate')
    Hub.listen("auth", (data) => {
      console.log("data:", data);
      const {payload} = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
      }
      if (payload.event === "signOut") {
        this.signedIn = false;
      }
    });
    Auth.currentAuthenticatedUser()
        .then((user) => {
          this.user = user;
          this.signedIn = true;
        })
        .catch((e) => {
          console.log('error', e)
          this.user = null;
          this.signedIn = false;
        });
  },
  mounted() {
  }
};
