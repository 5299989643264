const Validator = {
    validateStep1: function(data){
        let errors = []
        if(!data.firstName){
            errors.push('First name field is required')
        }

        if(!data.lastName){
            errors.push('First name field is required')
        }

        if(!data.npiyn){
            errors.push('NPI field is required')
        }

        if(!data.state){
            errors.push('State field is required')
        }

        return errors;
    },
    validateStep2: function(data){
        let errors = [];
        if(!data.classification){
            errors.push('Classification field is required');
        }

        if(!data.npi){
            errors.push('NPI field is required');
        }
        return errors;
    },
    validatePasswordStep: function(data){
        let errors = []
        if(data.password != data.confirmPassword){
            errors.push('Passwords don\'t match')
        }
        return errors;
    },
    validateResetPassword: function(data){
        let errors = []
        if(data.password != data.confirmPassword){
            errors.push('Passwords don\'t match')
        }
        return errors;
    },
    validateChangePassword: function(data){
        let errors = []
        if(!data.password){
            errors.push('Password is required')
        }

        if(!data.oldPassword){
            errors.push('Old Password is required')
        }
        return errors;
    },
    validatePasswordStrength: function(password){
        let report = {
            case: true,
            minlen: true,
            numeric: true
        }

        if(!password){
            report.case = false;
            report.minlen = false;
            report.numeric = false;
            return report;
        }

        if(password.length<8){
            report.minlen = false;
        }

        // Regular expression
        const numericRegex = /\d/;
        // Check if string contians numbers
        const doesItHaveNumber = numericRegex.test(password);
        if(!doesItHaveNumber){
            report.numeric = false;
        }

        // Regular expression
        const lowerCaseRegex = /[a-z]/;
        // Check if string contians numbers
        const lowerCaseCheck = lowerCaseRegex.test(password);
        if(!lowerCaseCheck){
            report.case = false;
        }

        const upperCaseRegex = /[A-Z]/;
        // Check if string contians numbers
        const upperCaseCheck = upperCaseRegex.test(password);
        if(!upperCaseCheck){
            report.case = false;
        }

        return report;
    },
    validateLogin: function(data){
        let errors = [];
        if(!data.email){
            errors.push('E-mail is required');
        }

        if(!data.password){
            errors.push('Password is required');
        }

        return errors;
    },
    validateForgotPassword: function(data){
        let errors = [];
        if(!data.email){
            errors.push('E-mail is required');
        }

        return errors;
    },
    validateAccountUpdate: function(data){
        let errors = []
        if(!data.firstName){
            errors.push('First name is required');
        }

        if(!data.lastName){
            errors.push('First name is required');
        }

        if(!data.state){
            errors.push('First name is required');
        }

        return errors;
    }
}
export default Validator;
