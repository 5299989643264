































































































































































































































import PasswordModal from '../components/auth/PasswordModal.vue';
import {updateUser} from "../graphql/mutations";
import {API, Auth} from 'aws-amplify';
import CollectionHelper from '../helpers/collection';
import Validator from '../helpers/validation';

import moment from 'moment';
import VeevaAPI from "@/api";

export default {
  name: 'Account',
  components: {
    PasswordModal
  },
  data: function () {
    return {
      error: null,
      progress: false,
      isNpiDisabled: true
    }
  },
  methods: {
    formatDate: function (date) {
      return moment(date).format('MMMM, DD');
    },
    documentUrl: function (id) {
      let url = `${window.location.origin}/prod/sagerx/document?documentId=${encodeURIComponent(id)}`;
      return url;
    },
    updateAccount: async function (e) {
      e.preventDefault();

      this.progress = true
      let errors = Validator.validateAccountUpdate(this.$store.state.profile.profile)
      if (errors.length > 0) {
        return;
      }

      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          "custom:classification": this.$store.state.profile.profile.classification == 'other' ? this.$store.state.profile.profile.otherClassification : this.$store.state.profile.profile.classification,
          "custom:firstName": this.$store.state.profile.profile.firstName,
          "custom:lastName": this.$store.state.profile.profile.lastName,
          "custom:state": this.$store.state.profile.profile.state
        });

        let userProfile = Object.assign({}, this.$store.state.profile.profile)
        delete userProfile.createdAt
        delete userProfile.updatedAt
        if (userProfile.classification == 'other') {
          userProfile.classification = userProfile.otherClassification
        }
        delete userProfile.otherClassification

        await API.graphql({
          query: updateUser,
          variables: {
            input: userProfile
          }
        })

        this.progress = false;
      } catch (error) {
        this.error = {
          message: error.message ? error.message : 'Unable to update account',
          code: 14
        }

        this.progress = false;
      }

    },
    togglePasswordModal: function () {
      this.$store.dispatch('modals/setShowChangePasswordModal', true)
    },
    isNpiValid: async function () {
      const npiValid = await VeevaAPI.npiValidation(this.$store.state.profile.profile.npi, this.$store.state.profile.profile.lastName)

      console.log('npiValid', this.$store.state.profile.profile.npi, npiValid)

      this.isNpiDisabled = !this.$store.state.profile.profile.npi || !npiValid
    }
  },
  computed: {
    profile: function () {
      return this.$store.state.profile.profile
    },
    requestsCount: function () {
      return this.$store.state.profile.requests.length
    },
    requests: function () {
      CollectionHelper.sortBy(this.$store.state.profile.requests, 'createdAt')
      let ret = CollectionHelper.groupBy(this.$store.state.profile.requests, 'date')
      return ret;
    },
    formErrors: function () {
      return Validator.validateAccountUpdate(this.$store.state.profile.profile)
    }
  },
  mounted: async function () {
    Auth.currentAuthenticatedUser()
        .then(async (user) => {
          await this.$store.dispatch('profile/loadProfile', user.attributes.email)
          await this.isNpiValid()
        })


  }

}
