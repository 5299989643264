








































































































import { Auth } from "aws-amplify";

export default {
  name: "home",
  props: ["toggle"],
  data() {
    return {
      formState: "signUp",
      form: {
        username: "",
        password: "",
        email: "",
        npi: "",
        classification: "",
        firstName: "",
        lastName: "",
      },
    };
  },
  methods: {
    async signUp() {
      const {
        password,
        email,
        classification,
        firstName,
        lastName,
        npi,
      } = this.form;
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          "custom:classification": classification,
          "custom:firstName": firstName,
          "custom:lastName": lastName,
          "custom:npi": npi,
        },
      });
      this.formState = "confirmSignUp";
    },
    async confirmSignUp() {
      const { email, authCode } = this.form;
      await Auth.confirmSignUp(email, authCode);
      alert("successfully signed up! Sign in to view the app.");
      this.toggle();
    },
  },
};
