

























































import { Auth, API, graphqlOperation } from "aws-amplify";
import { createStandardResponseLetterAccessRequest } from "../graphql/mutations";
import { onCreateStandardResponseLetterAccessRequest } from "../graphql/subscriptions";
import { listStandardResponseLetterAccessRequests } from "../graphql/queries";

export default {
  name: "protected",
  data() {
    return {
      formState: "idle",
      form: {
        doc_id: "",
      },
      loading: false,
      queryId: null,
      subscriptionStatus: "unsubscribed",
      documents: [],
      user_id: "d6505df7-f324-4ba1-adcd-0d187ec82102",
      subscription: undefined,
    };
  },

  methods: {
    async createDocumentRequest() {
      let userId = (await Auth.currentUserInfo()).attributes.sub; // TODO: verify using correct Attribute for lookup
      console.info("userId", userId);
      this.loading = true;
      const responseData = await API.graphql(
        graphqlOperation(createStandardResponseLetterAccessRequest, {
          input: {
            userID: userId,
            document_number: this.form.document_number,
          },
        })
      );
      console.log("responseData", responseData);
      this.loading = false;
    },
    async listDocumentRequests() {
      let userId = (await Auth.currentUserInfo()).attributes.sub; // TODO: verify using correct Attribute for lookup
      console.info("userId", userId);
      this.loading = true;

      const responseData = await API.graphql(
        graphqlOperation(listStandardResponseLetterAccessRequests, {
          filter: {
            userID: { eq: userId },
          },
        })
      );

      console.log("responseData", responseData);

      this.documents =
        // @ts-ignore
        responseData.data.listStandardResponseLetterAccessRequests.items;
      this.loading = false;
    },
    async subscribeToDocumentRequests() {
      console.log("subscribing");
      this.subscriptionStatus = "subscribed";
      this.subscription = API.graphql(
        graphqlOperation(onCreateStandardResponseLetterAccessRequest, {})
      )
        // @ts-ignore
        .subscribe({
          next: ({
            value: {
              data: { onCreateStandardResponseLetterAccessRequest },
            },
          }) => {
            console.log(
              "onCreateStandardResponseLetterAccessRequest",
              onCreateStandardResponseLetterAccessRequest
            );

            this.listDocumentRequests();
          },
        });
    },
    unsubscribeFromDocumentRequests() {
      // Stop receiving data updates from the subscription
      console.log("unsubscribing");
      this.subscription.unsubscribe();
      this.subscriptionStatus = "unsubscribed";
    },
  },
};
