









































































































































































































































































import API from '../api';
import {Auth, Hub} from 'aws-amplify';
import pdfjsLib from 'pdfjs-dist/build/pdf';
// import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import 'pdfjs-dist/web/pdf_viewer.css';
import {DocumentResult, PrescribingInformationSection} from '@/@types';

// const pdfjsLib = require("pdfjs-dist/build/pdf.min.js");
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js';

export default {
  name: 'Search',
  components: {},
  data: function () {
    return {
      signedIn: false,
      user: null,
      selectedDocument: null,
      progress: false,
      results: [],
      piSections: [],
      errors: [],
      noResultsFlag: false,
      isSearchCompleted: false,
      submittedForm: {},
      showZulressoBanner: false,
      showZurzuvaeBanner: false,
      showInvestigationalUseBanner: false,
      showApprovedProductBanner: false,
      searchForm: {
        searchKeywords: '',
        productCode: ''
      },
      products: [{'00P000000000401': 'ZULRESSO'}, {'00P000000000501': 'ZURZUVAE'}],
      showValidation: false
    };
  },
  beforeCreate: function () {
    let modal = this.$route.query.modal;

    if (modal) {
      console.log('modal', modal);
      this.$store.dispatch('modals/setShow' + modal, true);
    }

    Hub.listen('auth', (data) => {
      const {payload} = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
      }
      if (payload.event === 'signOut') {
        this.results = [];
        this.searchForm.searchKeywords = '';
        this.piSections = [];
        this.signedIn = false;
      }
    });

    Auth.currentAuthenticatedUser()
        .then((user) => {
          this.user = user;
          this.signedIn = true;
        })
        .catch((e) => {
          console.log('error', e)
          this.user = null;
          this.signedIn = false;
        });
  },
  computed: {
    showProductCodeInvalid: function () {
      return this.showValidation && !this.searchForm.productCode
    },
    showSearchKeywordsInvalid: function () {
      return this.showValidation && !this.searchForm.searchKeywords
    },
    isInvalid: function () {
      return this.showValidation && this.isMissingFields
    },
    isMissingFields: function () {
      return !this.searchForm.searchKeywords || !this.searchForm.productCode
    },
    piResults: function (): PrescribingInformationSection[] {
      return this.piSections;
    },
    srlResults: function () {
      return this.results
          .filter((document) => {
            if (document.title__v.startsWith('VV-MED')) {
              const title = document.title__v.split('ZULRESSO ')[1];
              document.title__v = 'ZULRESSO ' + title;
            }
            return document.classification__v == 'Standard Response';
          });
    },
    productLabel: function () {
      let productCode = this.searchForm.productCode;
      console.log('productCode', productCode)
      switch (productCode) {
        case '00P000000000401':
          return 'Approved Products_ZULRESSO® (brexanolone)'
        case '00P000000000501Z':
          return 'Approved Products_ZURZUVAE™ (zuranolone)'
        case '00P000000000501':
          return 'Investigational Compounds_Zuranolone'
        case '00P000000000A01':
          return 'Investigational Compounds_Sage-324'
        case '00P000000000402':
          return 'Investigational Compounds_Sage-718'

        default:
          return ''
      }
    }
  },
  methods: {
    trackDocumentAccess: function (name: string, documentNumber?: string) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'siteSearchResultAccessed',
          productCode: this.searchForm.productCode,
          searchTerm: this.searchForm.searchKeywords,
          documentName: name,
          documentNumber
        });
      }
    },
    trackSearch: function (searchResults: DocumentResult[]) {
      const searchKeywords = this.searchForm.searchKeywords;

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'siteSearch',
          productCode: this.searchForm.productCode,
          searchTerm: searchKeywords,
          searchResultCount: searchResults.length,
          searchResultsArray: searchResults.map((item) => {
            item.name__v;
          })
        });

        // ## Iterate & track each individual search result as a HIT
        if (searchResults.length) {
          searchResults.forEach((item) => {
            window.dataLayer.push({
              event: 'siteSearchResultDisplayed',
              productCode: this.searchForm.productCode,
              searchTerm: searchKeywords,
              documentName: item.name__v,
              documentNumber: item.document_number__v
            });
          });
        } else {
          // TODO: Track empty results
          window.dataLayer.push({
            event: 'siteSearchNoResults',
            productCode: this.searchForm.productCode,
            searchTerm: searchKeywords
          });
        }
      }

      // this.$ga.event('Search', searchKeywords, searchResults)
    },
    clearSearchQueryString: function () {

      this.searchForm.searchKeywords = '';

      this.$router.replace({
        query: {
          s: '',
          productCode: this.searchForm.productCode
        }
      });
    },
    clickSubmit: function (e) {
      e.preventDefault();

      if (!this.isMissingFields) {
        this.submitSearch();
      } else {
        this.showValidation = true;
      }
    },
    submitSearch: function () {

      if (this.isMissingFields) {
        this.showValidation = true;
        return;
      }

      this.progress = true;

      this.showZulressoBanner = false;
      this.showZurzuvaeBanner = false;
      this.showApprovedProductBanner = false;
      this.showInvestigationalUseBanner = false;

      let pdfPath;
      let productName;

      if (this.searchForm.productCode === '00P000000000401') {
        pdfPath = '/content/dam/medical_sagerx_com-v2/documents/prescribing-information-zulresso.pdf';
        productName = 'ZULRESSO';
        this.showZulressoBanner = true;
      } else {
        pdfPath = '/content/dam/medical_sagerx_com-v2/documents/zurzuvae-pi.pdf'
        productName = 'ZURZUVAE';
        this.showZurzuvaeBanner = true;
      }


      const loadingTask = pdfjsLib.getDocument(`${window.location.origin}${pdfPath}`);
      // const loadingTask = pdfjsLib.getDocument(`/src/assets/prescribing-information-zulresso.pdf`);

      console.log('loadingTask', loadingTask);

      let pi_destinations = [];

      this.piSections = [];
      this.results = [];
      this.message = null;
      this.submittedForm = Object.assign({}, this.searchForm);

      let dataLayerData = {
        event: 'search',
        searchType: 'Product Information',
        searchTerm: this.submittedForm.searchKeywords,
        product: this.productLabel
      }
      try {
        window.dataLayer.push(dataLayerData);
        // console.log(dataLayerData);
      } catch (e) {
        // empty
      }

      this.$router.replace({
        query: {
          s: this.submittedForm.searchKeywords,
          // TODO: Should we also be tracking the productCode here?
          productCode: this.submittedForm.productCode
        }
      });

      const searchKeywordsArray = this.submittedForm.searchKeywords.split(',');


      loadingTask.promise
          .then((pdfDocument) => {
            console.log('pdfDocument', pdfDocument);
            pdfDocument.getDestinations().then((dests) => {
              pi_destinations = Object.getOwnPropertyNames(dests);
              console.log('getDestinations', pi_destinations);
              let found = false;

              for (const keyword of searchKeywordsArray) {
                pi_destinations.forEach((pi_destination) => {

                  const pi_destination_keyword = pi_destination.split('--')[0];
                  if (pi_destination_keyword.toLowerCase() === keyword.trimStart().replaceAll(' ', '-').toLowerCase()) {
                    console.log('pi_destination_keyword', pi_destination_keyword, keyword);

                    const piSection = {
                      name: `${productName} Prescribing Information - ` + pi_destination.replaceAll('-', ' ').replaceAll('  ', ' - '),
                      url: `${window.location.origin}/pi-pdf-page?document=${pdfPath}&search=${pi_destination}`
                    };
                    this.piSections.push(piSection);

                    // TODO: Track piSection results to analytics

                    found = true;
                    this.noResultsFlag = false;
                  }
                });
              }

              if (found === false) {
                this.piSections = [];
              }
            });
          })
          .catch((e) => {
            console.log(e);
          });

      API.documentSearch(this.submittedForm.productCode, searchKeywordsArray.join(' AND '), 'library', 'srl')
          .then((res) => {
            this.results = res.data.data.data;
            this.containsAtLeastOneApprovedProduct(this.results);
            this.containsAtLeastOneInvestigationalUse(this.results);
            this.progress = false;
            this.noResultsFlag = false;
            if (this.results.length == 0 && this.piSections.length == 0) {
              this.noResultsFlag = true;
            }
            this.trackSearch(this.results);
            this.isSearchCompleted = true;
          })
          .catch((e) => {
            this.noResultsFlag = true;
            this.results = [];
            this.errors = e;
            this.progress = false;
            this.trackSearch(this.results);
          });
    },
    selectPiResult: function (document: PrescribingInformationSection, event) {
      // TODO: Verify we want PiResult tracking as well
      event.preventDefault();
      window.open(document.url, '_blank');

      this.trackDocumentAccess(document.name);
    },
    containsAtLeastOneInvestigationalUse: function (documents) {
      documents.forEach(doc => {
        if (doc.investigational_use__c) {
          this.showInvestigationalUseBanner = true;
          return;
        }
      })
      return;
    },
    containsAtLeastOneApprovedProduct: function (documents) {
      documents.forEach(doc => {

        console.log('doc', doc);
        if (!doc.investigational_use__c) {
          this.showApprovedProductBanner = true;
          return;
        }
      })
      return;
    },
    selectDocument: function (document: DocumentResult, event) {
      event.preventDefault();
      if (document.classification__v == 'Standard Response') {
        this.$store.dispatch('workflow/setSelectedDocument', document);
        this.$store.dispatch('workflow/setSearchData', this.submittedForm);

        // if logged in
        // trigger createsearchrequest (just for history)
        // open document
        // if (this.$store.state.isAuthenticated) {
        if (this.signedIn) {
          let user = this.$store.state.user
          user = this.user;
          console.log('user', user);
          // TODO: How is `workflow/trackSearch` used ? Do we include other tracking stats there?
          this.$store.dispatch('workflow/trackSearch', {
            user: user.attributes
          });

          this.trackDocumentAccess(document.name__v, document.document_number__v);
          let url = `${window.location.origin}/prod/sagerx/document?documentId=${encodeURIComponent(document.id)}`;
          window.open(url, '_blank');
        } else {
          this.$store.dispatch('workflow/setCurrentStep', 'search');
          this.$store.dispatch('modals/setShowSignupModal', true);

          // TODO: Track inauthenticated users trying to access a document
        }
      } else {
        this.trackDocumentAccess(document.name__v, document.document_number__v);
        // go to document
        let url = `${window.location.origin}/prod/sagerx/document?documentId=${encodeURIComponent(document.id)}`;
        window.open(url, '_blank');
      }
    },
    mounted: function () {
      console.log('mounted')

      this.$store.dispatch('modals/setCloseAllModals', true);
    },
    created: function () {

      console.log('created')

      // debugger; // eslint-disable-line

      // NOTE: If we don't grab the query params from the route, grab them from the outer window location!
      const urlSearchParams = new URLSearchParams(window.location.search)
      let querySearchTerm = this.$route.query.s ?? urlSearchParams.get('s');
      let product = this.$route.query.productCode ?? urlSearchParams.get('productCode');

      console.log('create', querySearchTerm);

      if (querySearchTerm) {
        this.searchForm.searchKeywords = querySearchTerm;
        this.searchForm.productCode = product;
        this.submitSearch();
      }
    }
  }
};
