import {GRAPHQL_AUTH_MODE} from "@aws-amplify/api-graphql";
import {API} from "aws-amplify";
import {startSearchRequest} from "../../graphql/mutations";
import VeevaAPI from '../../api'
import Formatter from '../../helpers/format'

const state = () => ({
    currentStep: null,
    selectedDocument: null,
    signupData: {},
    npiData: {},
    registrationData: {},
    searchRequest: {},
    searchData: {},
    error: null
})

export default {
    namespaced: true,
    state,
    mutations: {
        setCurrentStep(state, step) {
            state.currentStep = step;
        },
        setSelectedDocument(state, document) {
            state.selectedDocument = document;
        },
        setSignupData(state, data) {
            state.signupData = data;
        },
        setNPIData(state, data) {
            state.npiData = data;
        },
        setRegistrationData(state, data) {
            state.registrationData = data;
        },
        setSearchRequest(state, data) {
            state.searchRequest = data;
        },
        setSearchData(state, data) {
            state.searchData = {
                productCode: data.productCode,
                searchKeywords: data.searchKeywords.split(',')
            };
        },
        resetWorkflow(state) {
            state.searchData = {}
            state.registrationData = {}
            state.searchRequest = {}
            state.npiData = {}
            state.currentStep = null
            state.selectedDocument = null
            state.error = null
        },
        setError(state, error) {
            state.error = error
        }
    },
    actions: {
        setCurrentStep({commit}, step) {
            commit("setCurrentStep", step);
        },
        setSelectedDocument({commit}, document) {
            commit("setSelectedDocument", document);
        },
        setSignupData({commit}, data) {
            commit("setSignupData", data);
        },
        setNPIData({commit}, data) {
            commit("setNPIData", data);
        },
        setRegistrationData({commit}, data) {
            commit("setRegistrationData", data);
        },
        resetWorkflowError({commit}) {
            commit("setError", null)
        },
        setSearchData({commit}, data) {
            commit("setSearchData", data);
        },
        resetWorkflow({commit}) {
            commit("resetWorkflow", {});
        },
        async noNpiWorkflow({commit, dispatch, state}) {

            dispatch('modals/setShowSignupModal', false, {root: true})
            dispatch('modals/setShowProgressModal', true, {root: true})

            // npi validation
            let npiVerified = false

            console.log('selected document', state.selectedDocument)
            let classification = state.npiData.classification == 'other' ? state.npiData.otherClassification : state.npiData.classification;
            try {
                const responseData = await API.graphql({
                    query: startSearchRequest,
                    variables: {
                        input: {
                            documentNumber: state.selectedDocument ? state.selectedDocument.document_number__v : 'NA',
                            firstName: state.signupData.firstName,
                            lastName: state.signupData.lastName,
                            email: state.signupData.email,
                            documentMetadata: state.selectedDocument ? JSON.stringify(state.selectedDocument) : '',
                            npiVerified: npiVerified,
                            npi: state.npiData.npi,
                            classification: classification,
                            state: state.signupData.state,
                            searchProduct: state.searchData.productCode ? state.searchData.productCode : '',
                            searchKeywords: state.searchData.searchKeywords ? state.searchData.searchKeywords : ''
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                });

                console.log('response data', responseData)


                // @ts-ignore
                let searchRequest = responseData.data.startSearchRequest

                console.log('search request', searchRequest)
                commit("setSearchRequest", searchRequest)
                dispatch('modals/setShowProgressModal', false, {root: true})

                // show thank you
                dispatch("modals/setShowThankyouModal", true, {root: true})
            } catch (e) {
                console.log('error', e)
                let error = Formatter.extractError(e)
                commit('setError', error)
                dispatch('modals/setShowProgressModal', false, {root: true})
                dispatch('modals/setShowSignupModal', true, {root: true})
            }
        },
        async startWorkflow({commit, dispatch, state}) {

            // if(state.currentStep=='signup'){
            //     // npi validation
            //     let npiVerified = await VeevaAPI.npiValidation(state.npiData.npi, state.signupData.lastName)
            //     if(npiVerified){
            //         dispatch("modals/setShowPasswordModal", true, {root: true})
            //     } else {
            //         commit('setError', {
            //             message: 'Unable to validate NPI',
            //             code: 47
            //         })
            //     }
            // } else {

            //the signup workflow requires the same flow of request document access

            dispatch('modals/setShowNPIModal', false, {root: true})
            dispatch('modals/setShowProgressModal', true, {root: true})

            // npi validation
            let npiVerified = await VeevaAPI.npiValidation(state.npiData.npi, state.signupData.lastName)

            console.log('selected document', state.selectedDocument)
            let classification = state.npiData.classification == 'other' ? state.npiData.otherClassification : state.npiData.classification;
            try {
                const responseData = await API.graphql({
                    query: startSearchRequest,
                    variables: {
                        input: {
                            documentNumber: state.selectedDocument ? state.selectedDocument.document_number__v : 'NA',
                            firstName: state.signupData.firstName,
                            lastName: state.signupData.lastName,
                            email: state.signupData.email,
                            documentMetadata: state.selectedDocument ? JSON.stringify(state.selectedDocument) : '',
                            npiVerified: npiVerified,
                            npi: state.npiData.npi,
                            classification: classification,
                            state: state.signupData.state,
                            searchProduct: state.searchData.productCode ? state.searchData.productCode : '',
                            searchKeywords: state.searchData.searchKeywords ? state.searchData.searchKeywords : ''
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                });

                console.log('response data', responseData)


                // @ts-ignore
                let searchRequest = responseData.data.startSearchRequest

                console.log('search request', searchRequest)
                commit("setSearchRequest", searchRequest)
                dispatch('modals/setShowProgressModal', false, {root: true})

                if (npiVerified) {
                    // open password modal
                    dispatch("modals/setShowPasswordModal", true, {root: true})
                } else {
                    // show thank you
                    dispatch("modals/setShowThankyouModal", true, {root: true})
                }
            } catch (e) {
                console.log('error', e)
                let error = Formatter.extractError(e)
                commit('setError', error)
                dispatch('modals/setShowProgressModal', false, {root: true})
                dispatch('modals/setShowNPIModal', true, {root: true})
            }
            // }
        },
        async trackSearch({state}, data) {
            await API.graphql({
                query: startSearchRequest,
                variables: {
                    input: {
                        documentNumber: state.selectedDocument.document_number__v,
                        email: data.user.email,
                        documentMetadata: JSON.stringify(state.selectedDocument),
                        searchProduct: state.searchData.productCode,
                        searchKeywords: state.searchData.searchKeywords
                    }
                }
            });
        }
    }
};
