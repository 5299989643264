




import LoginModal from '../components/auth/LoginModal.vue'

export default {
  name: 'Login',
  components: {
      LoginModal
  },
  mounted: function() {
      if(!this.$store.state.isAuthenticated){
        this.$store.dispatch('modals/setShowLoginModal', true)
      }
  }
}
