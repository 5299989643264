import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify'

// import Home from './routes/Home.vue'
import Profile from './routes/Profile.vue'
import AuthComponent from './routes/Auth.vue'
import Protected from './routes/Protected.vue'
import Search from './routes/Search.vue'
import Login from './routes/Login.vue'
import Signup from './routes/Signup.vue'
import Admin from './routes/Admin.vue'
import ManageMyAccount from './routes/ManageMyAccount.vue'
import Recover from './routes/Recover.vue'
// import ForgotPassword from './routes/Forgot
// Password.vue'


const routes = [
  // { path: '/', component: Home },
  { path: '/auth', component: AuthComponent },
  { path: '/protected', component: Protected, meta: { requiresAuth: true} },
  { path: '/profile', component: Profile, meta: { requiresAuth: true} },
  { path: '/', component: Search },
  { path: '/login', component: Login , meta: { guestOnly: true} },
  // { path: '/forgot-password', component: ForgotPassword , meta: { guestOnly: true} },
  { path: '/signup', component: Signup, meta: { guestOnly: true} },
  { path: '/admin', component: Admin, meta: { guestOnly: true} },
  { path: '/recover', component: Recover, meta: { guestOnly: true} },
  { path: '/manage-my-account', component: ManageMyAccount, meta: {requiresAuth: true} },
  { path: '/managemyaccountNoLogin', component: ManageMyAccount },
]

const router = new VueRouter({
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // if (to.path === 'managemyaccount' && this?.$route.params.screenshot === true) {
    //   next()
    // } else {
      Auth.currentAuthenticatedUser().then(() => {
        next()
      }).catch(() => {
        next({
          path: '/login'
        });
      });
    // }
  }

  if (to.matched.some(record => record.meta.guestOnly)) {
    Auth.currentAuthenticatedUser().then(() => {
      next({
        path: '/'
      });
    }).catch(() => {
      next()
    });
  }

  next()
})

export default router
