/* Veeva Models */

import {LibrarySearchResult} from "@/@types/veeva";

/**
 * Wrapper For Veeva Library Results
 * @param data
 * @constructor
 */
export function createVeevaLibraryItem(data: LibrarySearchResult) {
    let _self = {
        id: undefined,
        documentNumber: undefined,
        title: undefined,
        name: undefined,
        abbreviatedCitation: undefined,
        firstAuthor: undefined,
        link: undefined,
        conferenceTitle: undefined,
        displayText: undefined,
        isInvestigationalUse: undefined,
        classification: undefined,
    };

    _self.id = data.id;
    _self.documentNumber = data.document_number__v
    _self.name = data?.name__v,
    _self.title = data.title__v
    _self.firstAuthor = data.first_author__c
    _self.abbreviatedCitation = data.abbreviated_citation__c
    _self.link = data.publication_link__c
    _self.conferenceTitle = data?.document_conference_name1__cr?.data?.[0]?.title__c ?? '';
    _self.isInvestigationalUse = data?.investigational_use__c;
    _self.classification = data?.classification__v;

    if ( _self.firstAuthor && _self.conferenceTitle) {

        _self.displayText = `${_self.firstAuthor}, ${_self.conferenceTitle}`
    } else if (_self.firstAuthor) {
        _self.displayText = `${_self.firstAuthor}`
    } else {
        _self.displayText = `${_self.conferenceTitle}`
    }

    return _self;
}
